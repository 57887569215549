import React from 'react';

//Material

//Icons

//Styles
import styled from 'styled-components';
import { ic_videocam } from 'react-icons-kit/md/ic_videocam';
import { videoConferenceStyles } from './videoConference.styles';
//Types

//Commons
import Text from '../../commons/Text';

//Components

import SideBar from '../../components/Drawer';

//Modules

//Assets/Images

//Actions

//Reducers

//Utils

const VideoConference: React.FC<any> = (props) => {
  React.useEffect(() => {
    /* eslint-disable-next-line */
  }, []);

  return (
    <SideBar title='Vidéo Conférence' icon={ic_videocam}>
      <Wrapper>
        <div className='card'>
          <Text fontSize={16} lineHeight={36}>
            Bonjour,
            <br />
            Retrouver ici votre escpace salle de réunion en video-conférence.
            Ceci est une option. <br /> Nous prendrons rapidement contact avec
            vous et dans les meilleurs délais afin de vous présenter l'ensemble
            des options de cet espce totalement simple d'utilisation et
            intuitif.
            <br /> Pour plus de renseignement, veuillez contacter la société
            NBILITY à l'adresse :<br />
            <a href='mailto: contact@nbility.fr'>contact@nbility.fr</a>
          </Text>
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => videoConferenceStyles()}
`;

export default VideoConference;
