import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { CircularProgress, FormControlLabel } from '@material-ui/core';
//Styles
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { intervenerProfileStyles, useStyles } from './intervenerProfile.styles';
//Types
import { IntervenerProfileFormProps } from './intervenerProfile.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
//Modules

//Assets/Images
import camera from '../../assets/images/camera.png';
//Actions
import {
  intervenerProfileGetIntervener,
  intervenerProfilePropsChanged,
  intervenerProfileCreateIntervener,
  intervenerProfileUpdateIntervener,
  intervenerProfileIntialState,
} from '../../actions/intervenerProfileActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const permissions = [
  {
    id: 'volunteer',
    label: 'Bénévole',
  },
  {
    id: 'professional',
    label: 'Professionnel',
  },
];

const CustomCheckbox = withStyles({
  root: {
    color: '#DD0089',
    '&$checked': {
      color: '#DD0089',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

const IntervenerProfile: React.FC<any> = (props) => {
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const classes = useStyles();
  const {
    firstName,
    lastName,
    email,
    address,
    country,
    codePostal,
    phoneNumber,
    mobile,
    photo,
    permission,
    accessBackOffice,
    firstNameError,
    lastNameError,
    emailError,
    permissionError,
    getIntervenerMessageError,
    messageError,
    title,
    textButton,
    loading,
    loadingPage,
  } = props;

  const { intervenerId } = props.match.params;
  const disabledField = !!intervenerId;

  React.useEffect(() => {
    if (intervenerId) {
      props.intervenerProfileGetIntervener(intervenerId);
    }
    props.intervenerProfileIntialState();
    /* eslint-disable-next-line */
  }, []);

  // const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   props.intervenerProfilePropsChanged(
  //     'accessBackOffice',
  //     event.target.checked
  //   );
  // };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.intervenerProfilePropsChanged('permission', event.target.value);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.intervenerProfilePropsChanged('photo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.intervenerProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleClickCancel = () => {
    props.history.goBack();
  };

  const handleClickSave = () => {
    if (intervenerId) {
      props.intervenerProfileUpdateIntervener(intervenerId, props, () => {
        props.snackbarShowMessage("L'intervenant est modifié avec succès");
        setTimeout(() => {
          props.history.push('/interveners');
        }, 2000);
      });
    } else {
      props.intervenerProfileCreateIntervener(props, () => {
        props.snackbarShowMessage("L'intervenant est ajouté avec succès");
        setTimeout(() => {
          props.history.push('/interveners');
        }, 2000);
      });
    }
  };

  if (intervenerId && loadingPage) {
    return (
      <Wrapper>
        <div className='card'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  }
  if (!loadingPage && getIntervenerMessageError) {
    return (
      <Wrapper>
        <div className='card'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {getIntervenerMessageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  const emptyImage =
    intervenerId &&
    `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ width: '1000px' }}
      >
        {title}
      </Text>
      <div className='card'>
        <div className='main-header'>
          <UploadImage
            image={photo}
            emptyImage={emptyImage}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </div>
        <div className='main-content'>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Input
                  value={firstName}
                  type='text'
                  name='firstName'
                  placeholder='Nom'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {firstNameError && <Text error>{firstNameError}</Text>}
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={lastName}
                  type='text'
                  name='lastName'
                  placeholder='Prénom'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {lastNameError && <Text error>{lastNameError}</Text>}
              </Grid>
              <Grid item xs={12}>
                <Input
                  // disabled={disabledField}
                  value={email}
                  type='text'
                  name='email'
                  placeholder='Email'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {emailError && <Text error>{emailError}</Text>}
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={address}
                  type='text'
                  name='address'
                  placeholder='Adresse'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={country}
                  type='text'
                  name='country'
                  placeholder='Ville'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={codePostal}
                  type='text'
                  name='codePostal'
                  placeholder='Code postal'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={phoneNumber}
                  type='text'
                  name='phoneNumber'
                  placeholder='Numéro de téléphone'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={mobile}
                  type='text'
                  name='mobile'
                  placeholder='Mobile'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginTop: '10px' }}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='permission'>Permission</InputLabel>
                    <Select
                      disabled={disabledField}
                      value={permission}
                      name='permission'
                      onChange={handleSelectChange}
                      label='Permission'
                      id='permission'
                      // inputProps={{ className: 'text-field-select' }}
                    >
                      {permissions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Text color='#373737'>{option.label}</Text>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {permissionError && <Text error>{permissionError}</Text>}
              </Grid>
              {/* <Grid item xs={6}>
                <div style={{ marginTop: '15px', marginLeft: '10px' }}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={accessBackOffice}
                        onChange={handleCheckBoxChange}
                        name='accessBackOffice'
                      />
                    }
                    label='Accéder au backoffice'
                  />
                </div>
              </Grid> */}
              <Grid item xs={6} container alignItems='center' justify='center'>
                {messageError && <Text error>{messageError}</Text>}
              </Grid>
            </Grid>
          </div>
          <div className='main-footer'>
            <Button onClick={handleClickCancel} width={180} margin={10}>
              Annuler
            </Button>
            <Button onClick={handleClickSave} width={180} margin={10}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                textButton
              )}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => intervenerProfileStyles()}
`;

const mapStateToProps = ({ intervenerProfile }: RootState) => ({
  firstName: intervenerProfile.firstName,
  lastName: intervenerProfile.lastName,
  email: intervenerProfile.email,
  address: intervenerProfile.address,
  country: intervenerProfile.country,
  codePostal: intervenerProfile.codePostal,
  phoneNumber: intervenerProfile.phoneNumber,
  mobile: intervenerProfile.mobile,
  photo: intervenerProfile.photo,
  permission: intervenerProfile.permission,
  accessBackOffice: intervenerProfile.accessBackOffice,

  firstNameError: intervenerProfile.firstNameError,
  lastNameError: intervenerProfile.lastNameError,
  emailError: intervenerProfile.emailError,
  permissionError: intervenerProfile.permissionError,
  getIntervenerMessageError: intervenerProfile.getIntervenerMessageError,
  messageError: intervenerProfile.messageError,
  title: intervenerProfile.title,
  textButton: intervenerProfile.textButton,
  loading: intervenerProfile.loading,
  loadingPage: intervenerProfile.loadingPage,
});

export const connector = connect(mapStateToProps, {
  intervenerProfileGetIntervener,
  intervenerProfilePropsChanged,
  intervenerProfileCreateIntervener,
  intervenerProfileUpdateIntervener,
  intervenerProfileIntialState,
});

export default connector(withSnackbar(IntervenerProfile));
