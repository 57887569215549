import { css } from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const campagneDetailStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 850px;

  .main-content {
    width: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .div-link-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .main-footer {
    display: flex;
    width: 630px;
    justify-content: space-between;
    margin-top: 60px;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '10px 0',
    },

    circularProgress: {
      color: '#ff5078',
    },
  })
);
