import {
  MANAGER_PROFILE_PROPS_CHANGED,
  MANAGER_PROFILE_PROPS_VALIDATION,
  GET_CURRENT_MANAGER_FAILED,
  GET_CURRENT_MANAGER_SUCCES,
  MANAGER_PROFILE_SUCCESS,
  MANAGER_PROFILE_FAILED,
  MANAGER_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  ManagerProfileState,
  ManagerProfileActionTypes,
} from '../modules/ManagerProfile/managerProfile.types';

const INITIAL_STATE: ManagerProfileState = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  country: '',
  codePostal: '',
  phoneNumber: '',
  mobile: '',
  photo: null,
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  messageError: '',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: ManagerProfileActionTypes
): ManagerProfileState => {
  switch (action.type) {
    case MANAGER_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case MANAGER_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case GET_CURRENT_MANAGER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loadingPage: false,
      };

    case GET_CURRENT_MANAGER_SUCCES:
      return { ...state, ...action.payload, loadingPage: false };

    case MANAGER_PROFILE_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
        loadingPage: false,
      };

    case MANAGER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loadingPage: false,
      };

    case MANAGER_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
