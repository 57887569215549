import {
  ADD_INTERVENER_PROPS_CHANGED,
  ADD_INTERVENER_PROPS_VALIDATION,
  ADD_INTERVENER_FAILED,
  ADD_INTERVENER_INITIAL_STATE,
} from '../actions/types';
import {
  AddIntervenerState,
  AddIntervenerActionTypes,
} from '../modules/AddIntervener/addIntervener.types';

const INITIAL_STATE: AddIntervenerState = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  country: '',
  codePostal: '',
  phoneNumber: '',
  mobile: '',
  photo: null,
  permission: 'volunteer',
  accessBackOffice: true,

  firstNameError: '',
  lastNameError: '',
  emailError: '',
  permissionError: '',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: AddIntervenerActionTypes
): AddIntervenerState => {
  switch (action.type) {
    case ADD_INTERVENER_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case ADD_INTERVENER_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case ADD_INTERVENER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case ADD_INTERVENER_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
