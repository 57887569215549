import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../reducers';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// LOGIN
export const LOGIN_PROPS_CHANGED = 'login_props_changed';
export const LOGIN_PROPS_VALIDATION = 'login_props_validation';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_INITIAL_STATE = 'login_initial_state';

// FORGOT PASSWORD
export const FORGOT_PASSWORD_PROPS_CHANGED = 'forgot_password_props_changed';
export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_success';
export const FORGOT_PASSWORD_FAILED = 'forgot_password_failed';
export const FORGOT_PASSWORD_INITIAL_STATE = 'forgot_password_initial_state';

// RESET PASSWORD
export const RESET_PASSWORD_PROPS_CHANGED = 'reset_password_props_changed';
export const RESET_PASSWORD_PROPS_VALIDATION =
  'reset_password_props_validation';
export const RESET_PASSWORD_VERIFICATION_TOKEN_FAILED =
  'reset_password_verification_token_failed';
export const RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS =
  'reset_password_verification_token_success';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILED = 'reset_password_failed';
export const RESET_PASSWORD_INITIAL_STATE = 'reset_password_initial_state';

//UPDATE PASSWORD
export const UPDATE_PASSWORD_PROPS_CHANGED = 'update_password_props_changed';
export const UPDATE_PASSWORD_PROPS_VALIDATION =
  'update_password_props_validation';
export const UPDATE_PASSWORD_SUCCESS = 'update_password_success';
export const UPDATE_PASSWORD_FAILED = 'update_password_failed';
export const UPDATE_PASSWORD_INITIAL_STATE = 'update_password_initial_state';

// MANAGER PROFILE
export const MANAGER_PROFILE_PROPS_CHANGED = 'manager_profile_props_changed';
export const MANAGER_PROFILE_PROPS_VALIDATION =
  'manager_profile_props_validation';
export const GET_CURRENT_MANAGER_FAILED = 'get_current_manager_failed';
export const GET_CURRENT_MANAGER_SUCCES = 'get_current_manager_succes';
export const MANAGER_PROFILE_SUCCESS = 'manager_profile_success';
export const MANAGER_PROFILE_FAILED = 'manager_profile_failed';
export const MANAGER_PROFILE_INITIAL_STATE = 'manager_profile_initial_state';

//GENERAL INFOS
export const GENERAL_INFOS_PROPS_CHANGED = 'general_infos_props_changed';
export const GENERAL_INFOS_GET_LIST_SUCCESS = 'general_infos_get_list_success';
export const GENERAL_INFOS_GET_LIST_FAILED = 'general_infos_get_list_failed';
export const GENERAL_INFOS_GET_LIST_FILTER_INFOS =
  'general_infos_get_list_filter_infos';
export const GENERAL_INFOS_INITIAL_STATE = 'general_infos_initial_state';

// REGION DEPARTMENT DETAIL
export const REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_SUCCESS =
  'region_department_detail_get_region_department_success';
export const REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_FAILED =
  'region_department_detail_get_region_department_failed';
export const REGION_DEPARTMENT_DETAIL_INITIAL_STATE =
  'region_department_detail_initial_state';

//INTERVENERS
export const INTERVENERS_PROPS_CHANGED = 'interveners_props_changed';
export const INTERVENERS_GET_LIST_SUCCESS = 'interveners_get_list_success';
export const INTERVENERS_GET_LIST_FAILED = 'interveners_get_list_failed';
export const INTERVENERS_GET_LIST_FILTER_INTERVENERS =
  'interveners_get_list_filter_interveners';
export const INTERVENERS_DELETE_INTERVENER_SUCCESS =
  'interveners_delete_intervener_success';
export const INTERVENERS_DELETE_INTERVENER_FAILED =
  'interveners_delete_intervener_failed';
export const INTERVENERS_INITIAL_STATE = 'interveners_initial_state';

//INTERVENER PROFILE
export const INTERVENER_PROFILE_GET_INTERVENER_SUCCESS =
  'intervener_profile_get_intervener_success';
export const INTERVENER_PROFILE_GET_INTERVENER_FAILED =
  'intervener_profile_get_intervener_failed';
export const INTERVENER_PROFILE_PROPS_CHANGED =
  'intervener_profile_props_changed';
export const INTERVENER_PROFILE_PROPS_VALIDATION =
  'intervener_profile_props_validation';
export const INTERVENER_PROFILE_CREATE_INTERVENER_SUCCESS =
  'intervener_profile_create_intervener_success';
export const INTERVENER_PROFILE_CREATE_INTERVENER_FAILED =
  'intervener_profile_create_intervener_failed';
export const INTERVENER_PROFILE_UPDATE_INTERVENER_SUCCESS =
  'intervener_profile_update_intervener_success';
export const INTERVENER_PROFILE_UPDATE_INTERVENER_FAILED =
  'intervener_profile_update_intervener_failed';
export const INTERVENER_PROFILE_INITIAL_STATE =
  'intervener_profile_initial_state';

// CAMPAGNES
export const CAMPAGNES_GET_LIST_INTERVENTIONS_SUCCESS =
  'campagnes_get_list_interventions_success';
export const CAMPAGNES_GET_LIST_INTERVENTIONS_FAILED =
  'campagnes_get_list_interventions_failed';
export const CAMPAGNES_PROPS_CHANGED = 'campagnes_props_changed';
export const CAMPAGNES_GET_LIST_SUCCESS = 'campagnes_get_list_success';
export const CAMPAGNES_GET_LIST_FAILED = 'campagnes_get_list_failed';
export const CAMPAGNES_GET_LIST_FILTER_CAMPAGNES =
  'campagnes_get_list_filter_campagnes';
export const CAMPAGNES_INITIAL_STATE = 'campagnes_initial_state';
export const CAMPAGNES_DELETE_CAMPAGNE_SUCCESS =
  'campagnes_delete_campagne_success';
export const CAMPAGNES_DELETE_CAMPAGNE_FAILED =
  'campagnes_delete_campagne_failed';
export const CAMPAGNES_DELETE_INTERVENTION_SUCCESS =
  'campagnes_delete_intervention_success';
export const CAMPAGNES_DELETE_INTERVENTION_FAILED =
  'campagnes_delete_intervention_failed';

// CAMPAGNE DETAIL
export const CAMPAGNE_DETAIL_GET_CAMPAGNE_SUCCESS =
  'campagne_detail_get_campagne_success';
export const CAMPAGNE_DETAIL_GET_CAMPAGNE_FAILED =
  'campagne_detail_get_campagne_failed';
export const CAMPAGNE_DETAIL_INITIAL_STATE = 'campagne_detail_initial_state';

//CAMPAGNE PROFILE
export const CAMPAGNE_GET_All_LIST_SUCCESS = 'campagne_get_all_list_success';
export const CAMPAGNE_GET_All_LIST_FAILED = 'campagne_get_all_list_failed';
export const CAMPAGNE_PROFILE_GET_CAMPAGNE_SUCCESS =
  'campagne_profile_get_campagne_success';
export const CAMPAGNE_PROFILE_GET_CAMPAGNE_FAILED =
  'campagne_profile_get_campagne_failed';
export const CAMPAGNE_PROFILE_PROPS_CHANGED = 'campagne_profile_props_changed';
export const CAMPAGNE_PROFILE_PROPS_VALIDATION =
  'campagne_profile_props_validation';
export const CAMPAGNE_PROFILE_GET_CAMPAGNE_SUMMARY =
  'campagne_profile_get_campagne_summary';
export const CAMPAGNE_PROFILE_CREATE_CAMPAGNE_SUCCESS =
  'campagne_profile_create_campagne_success';
export const CAMPAGNE_PROFILE_CREATE_CAMPAGNE_FAILED =
  'campagne_profile_create_campagne_failed';
export const CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_SUCCESS =
  'campagne_profile_update_campagne_success';
export const CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_FAILED =
  'campagne_profile_update_campagne_failed';
export const CAMPAGNE_PROFILE_INITIAL_STATE = 'campagne_profile_initial_state';

//ADD INTERVENER
export const ADD_INTERVENER_PROPS_CHANGED = 'add_intervener_props_changed';
export const ADD_INTERVENER_PROPS_VALIDATION =
  'add_intervener_props_validation';
export const CAMPAGNE_PROFILE_ADD_INTERVENER =
  'campagne_profile_add_intervener';
export const ADD_INTERVENER_FAILED = 'add_intervener_failed';
export const ADD_INTERVENER_INITIAL_STATE = 'add_intervener_initial_state';

//ADD INTERVENTION
export const ADD_INTERVENTION_PROPS_CHANGED = 'add_intervention_props_changed';
export const CAMPAGNE_PROFILE_ADD_INTERVENTION =
  'campagne_profile_add_intervention';
export const ADD_INTERVENTION_FAILED = 'add_intervention_failed';
export const ADD_INTERVENTION_INITIAL_STATE = 'add_intervention_initial_state';

//AUTOMATED TEXTS
export const AUTOMATED_TEXTS_PROPS_CHANGED = 'automated_texts_props_changed';
export const AUTOMATED_TEXTS_GET_TEXTS_SUCCESS =
  'automated_texts_get_texts_success';
export const AUTOMATED_TEXTS_GET_TEXTS_FAILED =
  'automated_texts_get_texts_failed';
export const AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS =
  'automated_texts_update_texts_success';
export const AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED =
  'automated_texts_update_texts_failed';
export const AUTOMATED_TEXTS_INITIAL_STATE = 'automated_texts_initial_state';

// STATICS
export const STATISTICS_GET_COMPAIGNS_SUCCEED =
  'STATISTICS_GET_COMPAIGNS_SUCCEED';
export const STATISTICS_GET_COMPAIGNS_LOADING =
  'STATISTICS_GET_COMPAIGNS_LOADING';
export const STATISTICS_GET_COMPAIGNS_FAILED =
  'STATISTICS_GET_COMPAIGNS_FAILED';

export const STATISTICS_GET_STATS_SUCCEED = 'STATISTICS_GET_STATS_SUCCEED';
export const STATISTICS_GET_STATS_LOADING = 'STATISTICS_GET_STATS_LOADING';
export const STATISTICS_GET_STATS_FAILED = 'STATISTICS_GET_STATS_FAILED';
