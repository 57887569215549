import axios from 'axios';
import moment from 'moment';
import { Event } from 'react-big-calendar';

import { URL } from './configApi';
import {
  CAMPAGNES_GET_LIST_INTERVENTIONS_SUCCESS,
  CAMPAGNES_GET_LIST_INTERVENTIONS_FAILED,
  CAMPAGNES_PROPS_CHANGED,
  CAMPAGNES_GET_LIST_SUCCESS,
  CAMPAGNES_GET_LIST_FAILED,
  CAMPAGNES_GET_LIST_FILTER_CAMPAGNES,
  CAMPAGNES_DELETE_CAMPAGNE_SUCCESS,
  CAMPAGNES_DELETE_CAMPAGNE_FAILED,
  CAMPAGNES_DELETE_INTERVENTION_SUCCESS,
  CAMPAGNES_DELETE_INTERVENTION_FAILED,
  CAMPAGNES_INITIAL_STATE,
  AppThunk,
} from './types';

import { GetUsersApiResponse, UserApiResponse } from './typesApi';
import {
  CampagnesPropsChangedAction,
  CampagnesInitialStateAction,
} from '../modules/Campagnes/campagnes.types';

export const campagnesPropsChanged = (
  prop: string,
  value: any
): CampagnesPropsChangedAction => ({
  type: CAMPAGNES_PROPS_CHANGED,
  prop,
  value,
});

export const campagnesGetAllCampagnes = (): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/campagnes`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        const events = getListEventsFromData(data.response.data);
        dispatch({
          type: CAMPAGNES_GET_LIST_SUCCESS,
          payload: data.response.data,
          events,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: CAMPAGNES_GET_LIST_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const campagnesGetAllInterventions = (): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/interventions`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: CAMPAGNES_GET_LIST_INTERVENTIONS_SUCCESS,
          payload: data.response.data.map((intervention: any) => ({
            _id: intervention._id,
            name: intervention.name,
          })),
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: CAMPAGNES_GET_LIST_INTERVENTIONS_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const campagnesGetFilterCampagnes =
  (search: string, campagnes: Array<any>): AppThunk =>
  (dispatch) => {
    const campagnesFilter: Array<any> = Object.assign([], campagnes);

    dispatch({
      type: CAMPAGNES_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    const result = campagnesFilter.filter((item: any) =>
      item.name.toLocaleLowerCase().match(search.toLocaleLowerCase())
    );

    dispatch({
      type: CAMPAGNES_GET_LIST_FILTER_CAMPAGNES,
      search,
      payload: result,
    });
  };

export const camapgnesDeleteCampagne =
  (campagneId: string, campagnes: Array<any>, callback: () => void): AppThunk =>
  (dispatch) => {
    dispatch({
      type: CAMPAGNES_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .delete(`${URL}/campagnes/${campagneId}`)
      .then((response) => {
        const newCampagnesList: Array<any> = Object.assign([], campagnes);
        if (response.status === 204) {
          dispatch({
            type: CAMPAGNES_DELETE_CAMPAGNE_SUCCESS,
            payload: newCampagnesList.filter(
              (campagne: any) => campagne._id !== campagneId
            ),
          });
        }
        callback();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: CAMPAGNES_DELETE_CAMPAGNE_FAILED,
            payload: 'Aucune campagne trouvée avec cet identifiant.',
          });
        }
      });
  };

export const camapgnesDeleteIntervention =
  (
    interventionId: string,
    interventions: Array<any>,
    callback: () => void
  ): AppThunk =>
  (dispatch) => {
    dispatch({
      type: CAMPAGNES_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .delete(`${URL}/interventions/${interventionId}`)
      .then((response) => {
        const newInterventionsList: Array<any> = Object.assign(
          [],
          interventions
        );
        if (response.status === 204) {
          dispatch({
            type: CAMPAGNES_DELETE_INTERVENTION_SUCCESS,
            payload: newInterventionsList.filter(
              (intervention: any) => intervention._id !== interventionId
            ),
          });
        }
        callback();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: CAMPAGNES_DELETE_INTERVENTION_FAILED,
            payload: 'Aucune intervention trouvée avec cet identifiant.',
          });
        }
      });
  };

export const campagnesInitialState = (): CampagnesInitialStateAction => ({
  type: CAMPAGNES_INITIAL_STATE,
});

const getListEventsFromData = (data: Array<any>): Array<Event> => {
  const events: Array<any> = [];

  data.forEach((item) => {
    if (item.startDate && item.endDate) {
      events.push({
        title: item.name,
        start: moment(item.startDate).toDate(),
        end: moment(item.endDate).toDate(),
        resource: {
          id: item._id,
        },
      });
    }
  });
  return events;
};

const transformToDate = (value: any) => {
  const [day, month, year] = value.split('/');

  return new Date(
    Number.parseInt(year, 10),
    Number.parseInt(month, 10) - 1,
    Number.parseInt(day, 10)
  );
};
