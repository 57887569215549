import React from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { ThemeProvider } from 'styled-components';
import thunk from 'redux-thunk';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './App.css';
// Reducers
import reducers from './reducers';
// Utils
import { PrivateRoute } from './utils/navigation';
import { removeItemFromLocalStorage } from './utils/storage';

// MODULES
import NotFound from './modules/NotFound';
import Login from './modules/Login';
import ManagerProfile from './modules/ManagerProfile';
import UpdatePassword from './modules/UpdatePassword';
import ResetPassword from './modules/ResetPassword';
import AutomatedTexts from './modules/AutomatedTexts';
import VideoConference from './modules/VideoConference';
import Publicites from './modules/Publicites';
import Statistics from './modules/Statistics';
// Routes
import GeneralInfosRouter from './routes/GeneralInfosRouter';
import IntervenersRouter from './routes/IntervenersRouter';
import CampagnesRouter from './routes/CampagnesRouter';

export const theme = {
  primary: '#FF7E04',
  secondary: '#DD0089',
  bluePrimary: '#1A2D35',
  white: '#ffffff',
};

const composeEnhancers = composeWithDevTools(applyMiddleware(thunk));
const store = createStore(reducers, composeEnhancers);

const App: React.FC = (props) => (
  // removeItemFromLocalStorage('token');
  // removeItemFromLocalStorage('user');
  <Provider store={store}>
    {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Redirect exact from='/' to='/login' />
          <Route path='/login' component={Login} exact />
          <Route
            path='/reset-password/:token'
            component={ResetPassword}
            exact
          />
          <PrivateRoute path='/update-profile' component={ManagerProfile} />
          <PrivateRoute path='/update-password' component={UpdatePassword} />
          <PrivateRoute path='/general-infos' component={GeneralInfosRouter} />
          <PrivateRoute path='/interveners' component={IntervenersRouter} />
          <PrivateRoute path='/campagnes' component={CampagnesRouter} />
          <PrivateRoute path='/automated-texts' component={AutomatedTexts} />
          <PrivateRoute path='/video-conference' component={VideoConference} />
          <PrivateRoute path='/publicites' component={Publicites} />
          <PrivateRoute path='/statistics' component={Statistics} />
          <Route path='/*' component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
    {/* </MuiPickersUtilsProvider> */}
  </Provider>
);
export default App;
