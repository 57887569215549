import axios from 'axios';
import { URL } from './configApi';
import {
  LOGIN_PROPS_CHANGED,
  LOGIN_PROPS_VALIDATION,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_INITIAL_STATE,
  AppThunk,
} from './types';
// Types
import {
  LoginPropsChangedAction,
  LoginState,
} from '../modules/Login/login.types';
import { LoginApiResponse } from './typesApi';
// Utils
import { setItemInLocalStorage } from '../utils/storage';
import { validateFieldsLogin } from '../modules/Login/login.utils';

export const loginPropsChanged = (
  prop: string,
  value: any
): LoginPropsChangedAction => ({ type: LOGIN_PROPS_CHANGED, prop, value });

export const onLogin =
  (props: LoginState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsLogin(props);

    dispatch({ type: LOGIN_PROPS_VALIDATION, payload: newProps });

    if (valid) {
      dispatch({
        type: LOGIN_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });

      axios
        .post(`${URL}/users/login`, {
          email: props.email,
          password: props.password,
        })
        .then(({ data }: { data: LoginApiResponse }) => {
          if (data && data.data.user) {
            setItemInLocalStorage('token', data.token);
            setItemInLocalStorage('user', data.data.user);
            dispatch({ type: LOGIN_SUCCESS });
            callback();
            dispatch({
              type: LOGIN_INITIAL_STATE,
            });
          } else {
            dispatch({
              type: LOGIN_FAILED,
              payload: 'Utilisateur non autorisé',
            });
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            dispatch({
              type: LOGIN_FAILED,
              payload: "Ce compte n'existe pas",
            });
          } else if (error.response?.status === 406) {
            dispatch({
              type: LOGIN_FAILED,
              payload: 'Votre Password est incorrect',
            });
          } else if (error.response?.status === 403) {
            dispatch({
              type: LOGIN_FAILED,
              payload: "Vous n'êtes pas autorisé à accéder au tableau de bord",
            });
          } else {
            dispatch({
              type: LOGIN_FAILED,
              payload: 'Network Error',
            });
          }
        });
    }
  };
