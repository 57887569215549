import styled, { css } from 'styled-components';

export const statisticsStyles = () => css`
  display: flex;
  flex-direction: column;
  .main-header {
    display: flex;
    width: 1000px;
    margin-bottom: 25px;
    margin-top: 15px;
  }
  .filter-by {
    width: 180px;
  }
`;

type LegendMarkProps = {
  color: string;
};

export const LegendMark = styled.div<LegendMarkProps>`
  height: 14px;
  width: 14px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 8px;
`;
