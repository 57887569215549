import {
  INTERVENERS_PROPS_CHANGED,
  INTERVENERS_GET_LIST_SUCCESS,
  INTERVENERS_GET_LIST_FAILED,
  INTERVENERS_GET_LIST_FILTER_INTERVENERS,
  INTERVENERS_DELETE_INTERVENER_SUCCESS,
  INTERVENERS_DELETE_INTERVENER_FAILED,
  INTERVENERS_INITIAL_STATE,
} from '../actions/types';
import {
  IntervenersState,
  IntervenersActionTypes,
} from '../modules/Interveners/interveners.types';

const INITIAL_STATE: IntervenersState = {
  interveners: [],
  intervenersFilter: [],
  search: '',
  filter: 'all',
  messageError: '',
  loading: true,
};

export default (
  state = INITIAL_STATE,
  action: IntervenersActionTypes
): IntervenersState => {
  switch (action.type) {
    case INTERVENERS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case INTERVENERS_GET_LIST_SUCCESS:
      return {
        ...state,
        interveners: action.payload,
        intervenersFilter: action.payload,
        loading: false,
      };
    case INTERVENERS_GET_LIST_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case INTERVENERS_GET_LIST_FILTER_INTERVENERS: {
      return {
        ...state,
        search: action.search,
        interveners: action.payload,
        loading: false,
      };
    }

    case INTERVENERS_DELETE_INTERVENER_SUCCESS:
      return { ...state, interveners: action.payload, loading: false };
    case INTERVENERS_DELETE_INTERVENER_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case INTERVENERS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
