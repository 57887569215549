import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
//Material

//Styles

import styled from 'styled-components';
import { intervenersStyles } from './interveners.styles';
//Types
import { IntervenersListProps } from './interveners.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import EmptyData from '../../components/EmptyData';
import Table from '../../components/Tables/IntervenersTable';
import Dialog from '../../components/Dialog';
//Modules

//Assets/Images
import emptyState from '../../assets/images/emptyState.svg';
//Actions
import {
  intervenersGetListInterveners,
  intervenersPropsChanged,
  intervenersGetFilterInterveners,
  intervenersDeleteIntervener,
  intervenersInitialState,
} from '../../actions/intervenersActions';
//Reducers
import { RootState } from '../../reducers';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const Interveners: React.FC<any> = (props) => {
  const {
    interveners,
    intervenersFilter,
    search,
    filter,
    messageError,
    loading,
  } = props;
  const [page, setPage] = React.useState(0);
  const [limit, setlimitPage] = React.useState(10);
  const [intervenerId, setIntervenerId] = React.useState('');
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    //  props.usersGetAllUsers(page, limit);
    props.intervenersGetListInterveners(filter);
    /* eslint-disable-next-line */
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setlimitPage(+event.target.value);
    setPage(0);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.intervenersGetFilterInterveners(
      event.target.value,
      intervenersFilter
    );
    //  props.usersPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSearch = () => {
    props.intervenersGetFilterInterveners(search, intervenersFilter);
  };

  const handleClickDelete = () => {
    props.intervenersDeleteIntervener(intervenerId, interveners, () => {
      props.snackbarShowMessage('Intervenant est supprimé avec succès');
      setOpen(false);
    });
  };

  const handleClickOpenDialog = (intervenerId: string) => {
    setOpen(true);
    setIntervenerId(intervenerId);
  };

  const handleClickCloseDialog = () => {
    setOpen(false);
  };

  const handleClickFilter = (filter: string) => {
    props.intervenersPropsChanged('filter', filter);
    props.intervenersGetListInterveners(filter);
  };

  return (
    <Wrapper>
      <div className='main-header'>
        <div className='div-input-search'>
          <Input
            value={search}
            type='text'
            name='search'
            placeholder='Rechercher'
            width={800}
            margin='10px 0'
            className='input-search'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEventChange(e)
            }
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#373737'
            className='button-search'
          >
            Rechercher
          </Button>
        </div>

        <Button
          onClick={() => props.history.push('/interveners/add-intervener')}
          width={150}
          margin={10}
        >
          Ajouter Intervenant
        </Button>
      </div>
      <div className='list-header'>
        <Text
          fontWeight={600}
          fontSize={16}
          lineHeight={24}
          style={{ marginRight: '30px' }}
        >
          Liste des Intervenants
        </Text>
        <Button
          color={filter === 'all' ? '#FF7E04' : '#C0C0C0'}
          background='#FBFAFA'
          onClick={() => handleClickFilter('all')}
        >
          Tous
        </Button>
        <Button
          color={filter === 'volunteer' ? '#FF7E04' : '#C0C0C0'}
          background='#FBFAFA'
          onClick={() => handleClickFilter('volunteer')}
        >
          Bénévole
        </Button>
        <Button
          color={filter === 'professional' ? '#FF7E04' : '#C0C0C0'}
          background='#FBFAFA'
          onClick={() => handleClickFilter('professional')}
        >
          Professionnel
        </Button>
      </div>

      <div className='card'>
        <Dialog
          open={open}
          subject='Supprimer Intervenant'
          content='Êtes-vous sûr de vouloir supprimer cet intervenant?'
          agreeText='Supprimer'
          handleAgree={handleClickDelete}
          handleClose={handleClickCloseDialog}
        />
        <EmptyData
          data={interveners}
          loading={loading}
          message='La liste des intervenants est vide'
          messageError={messageError}
          image={emptyState}
        >
          <Table
            data={interveners}
            count={interveners.length}
            rowsPerPage={limit}
            page={page}
            changePage={handleChangePage}
            changeRowsPerPage={handleChangeRowsPerPage}
            clickDelete={(intervenerId) => {
              handleClickOpenDialog(intervenerId);
            }}
          />
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => intervenersStyles()}
`;

const mapStateToProps = ({ interveners }: RootState) => ({
  interveners: interveners.interveners,
  intervenersFilter: interveners.intervenersFilter,
  search: interveners.search,
  filter: interveners.filter,
  messageError: interveners.messageError,
  loading: interveners.loading,
});

export const connector = connect(mapStateToProps, {
  intervenersGetListInterveners,
  intervenersPropsChanged,
  intervenersGetFilterInterveners,
  intervenersDeleteIntervener,
  intervenersInitialState,
});

export default connector(withSnackbar(Interveners));
