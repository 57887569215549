import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
// Styles
import { ic_settings_input_svideo } from 'react-icons-kit/md/ic_settings_input_svideo';
import { useStyles } from './campagnes.styles';
// Icons
// Components
import SideBar from '../../components/Drawer';
// Modules
import Campagnes from '../../modules/Campagnes';
import Campagne from '../../modules/CampagneProfile';
import NotFound from '../../modules/NotFound';

const CampagnesRouter: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title='Campagnes' icon={ic_settings_input_svideo}>
        <Switch>
          <Route path='/campagnes' component={Campagnes} exact />
          <Route path='/campagnes/add-campagne' component={Campagne} />
          <Route path='/campagnes/:campagneId' component={Campagne} />
          <Route path='/campagnes/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default CampagnesRouter;
