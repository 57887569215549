import {
  GENERAL_INFOS_PROPS_CHANGED,
  GENERAL_INFOS_GET_LIST_SUCCESS,
  GENERAL_INFOS_GET_LIST_FAILED,
  GENERAL_INFOS_GET_LIST_FILTER_INFOS,
  GENERAL_INFOS_INITIAL_STATE,
} from '../actions/types';
import {
  GeneralInfosState,
  GeneralInfosActionTypes,
} from '../modules/GeneralInfos/genralInfos.types';

const INITIAL_STATE: GeneralInfosState = {
  infos: [],
  infosFilter: [],
  search: '',
  messageError: '',
  loading: true,
};

export default (
  state = INITIAL_STATE,
  action: GeneralInfosActionTypes
): GeneralInfosState => {
  switch (action.type) {
    case GENERAL_INFOS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case GENERAL_INFOS_GET_LIST_SUCCESS:
      return {
        ...state,
        infos: action.payload,
        infosFilter: action.payload,
        loading: false,
      };
    case GENERAL_INFOS_GET_LIST_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case GENERAL_INFOS_GET_LIST_FILTER_INFOS: {
      return {
        ...state,
        search: action.search,
        infos: action.payload,
        loading: false,
      };
    }

    case GENERAL_INFOS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
