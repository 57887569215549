import React from 'react';
//Styles
import styled from 'styled-components';
//Material
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_control_point } from 'react-icons-kit/md/ic_control_point';
import { tagsStyles } from './tags.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  })
);

export interface TagsProps {
  hideIconAdd?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  options?: Array<any>;
  tags: any;
  placeholder: string;
  getTags: (tags: any) => void;
  handleClickAdd?: () => void;
}

const Tags: React.FC<TagsProps> = (props) => {
  const classes = useStyles();

  const {
    hideIconAdd,
    disabled,
    multiple,
    options,
    tags,
    placeholder,
    getTags,
    handleClickAdd,
  } = props;

  return (
    <Wrapper>
      <div className={classes.root}>
        <Autocomplete
          disabled={disabled}
          options={options || []}
          multiple={multiple}
          getOptionLabel={(option) => (option.name ? option.name : '')}
          value={tags}
          onChange={(event, value) => getTags(value)}
          renderTags={(tags: Array<any>, getTagProps) =>
            tags.map((value: any, index: number) => (
              <Chip
                key={value.id}
                variant='default'
                label={value.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant='outlined'
              // label='Responsables'
              placeholder={placeholder}
            />
          )}
        />
      </div>
      {!hideIconAdd && (
        <div
          role='button'
          tabIndex={0}
          className='icon-plus-cnt'
          onClick={handleClickAdd}
        >
          <Icon
            icon={ic_control_point}
            size='25'
            style={{ color: '#373737' }}
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => tagsStyles()}
`;

export default Tags;
