import React from 'react';
import { connect } from 'react-redux';
// Material
import { CircularProgress } from '@material-ui/core';
// Styles
import styled from 'styled-components';
// Types
//Icons
import { Icon } from 'react-icons-kit';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';
import { UpdatePasswordFormProps } from './updatePassword.types';
import { updatePasswordStyles } from './updatePassword.styles';
// Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
// Components
import SideBar from '../../components/Drawer';
// Actions
import {
  updatePasswordPropsChanged,
  updatePasswordInitialState,
  updatePasswordConfirm,
} from '../../actions/updatePasswordActions';
// Reducers
import { RootState } from '../../reducers';
// Utils
import { removeItemFromLocalStorage } from '../../utils/storage';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const UpdatePassword: React.FC<any> = (props) => {
  const [visiblePasswordCurrent, setVisiblePasswordCurrent] =
    React.useState<boolean>(false);
  const [visibleNewPassword, setVisibleNewPassword] =
    React.useState<boolean>(false);
  const [visiblePasswordConfirm, setVisiblePasswordConfirm] =
    React.useState<boolean>(false);
  const {
    passwordCurrent,
    newPassword,
    passwordConfirm,
    passwordCurrentError,
    newPasswordError,
    passwordConfirmError,
    loading,
  } = props;

  React.useEffect(() => {
    props.updatePasswordInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.updatePasswordPropsChanged(event.target.name, event.target.value);
  };

  const handleClickUpdatePassword = () => {
    props.updatePasswordConfirm(props, async () => {
      props.snackbarShowMessage(
        'Le mot de passe est changé avec succès, veuillez vous reconnecter '
      );
      setTimeout(async () => {
        await removeItemFromLocalStorage('token');
        await removeItemFromLocalStorage('user');
        props.history.push('/login');
      }, 3000);
    });
  };
  return (
    <SideBar>
      <Wrapper>
        <div className='card'>
          <Text fontWeight={600} fontSize={24} lineHeight={36}>
            Change mot de passe
          </Text>
          <div className='main-content'>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                value={passwordCurrent}
                type={visiblePasswordCurrent ? 'text' : 'password'}
                name='passwordCurrent'
                placeholder='Mot de passe actuel'
                width={400}
                margin='10px 0'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEventChange(e)
                }
              />
              <Icon
                onClick={() =>
                  setVisiblePasswordCurrent(!visiblePasswordCurrent)
                }
                icon={
                  visiblePasswordCurrent ? ic_visibility_off : ic_remove_red_eye
                }
                size='24'
                style={{
                  position: 'absolute',
                  right: 10,
                  color: '#c0c0c0',
                  cursor: 'pointer',
                }}
              />
            </div>
            {passwordCurrentError && (
              <Text error className='text-error'>
                {passwordCurrentError}
              </Text>
            )}
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                value={newPassword}
                type={visibleNewPassword ? 'text' : 'password'}
                name='newPassword'
                placeholder='Nouveau mot de passe'
                width={400}
                margin='10px 0'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEventChange(e)
                }
              />
              <Icon
                onClick={() => setVisibleNewPassword(!visibleNewPassword)}
                icon={
                  visibleNewPassword ? ic_visibility_off : ic_remove_red_eye
                }
                size='24'
                style={{
                  position: 'absolute',
                  right: 10,
                  color: '#c0c0c0',
                  cursor: 'pointer',
                }}
              />
            </div>
            {newPasswordError && (
              <Text error className='text-error'>
                {newPasswordError}
              </Text>
            )}
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                value={passwordConfirm}
                type={visiblePasswordConfirm ? 'text' : 'password'}
                name='passwordConfirm'
                placeholder='Confirmez le nouveau mot de passe'
                margin='10px 0'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEventChange(e)
                }
                width={400}
              />
              <Icon
                onClick={() =>
                  setVisiblePasswordConfirm(!visiblePasswordConfirm)
                }
                icon={
                  visiblePasswordConfirm ? ic_visibility_off : ic_remove_red_eye
                }
                size='24'
                style={{
                  position: 'absolute',
                  right: 10,
                  color: '#c0c0c0',
                  cursor: 'pointer',
                }}
              />
            </div>
            {passwordConfirmError && (
              <Text error className='text-error'>
                {passwordConfirmError}
              </Text>
            )}
          </div>
          <Button onClick={handleClickUpdatePassword} width={180} margin={10}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Enregistrer'
            )}
          </Button>
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => updatePasswordStyles()}
`;

const mapStateToProps = ({ updatePassword }: RootState) => ({
  passwordCurrent: updatePassword.passwordCurrent,
  newPassword: updatePassword.newPassword,
  passwordConfirm: updatePassword.passwordConfirm,
  passwordCurrentError: updatePassword.passwordCurrentError,
  newPasswordError: updatePassword.newPasswordError,
  passwordConfirmError: updatePassword.passwordConfirmError,
  loading: updatePassword.loading,
});

export const connector = connect(mapStateToProps, {
  updatePasswordPropsChanged,
  updatePasswordInitialState,
  updatePasswordConfirm,
});

export default connector(withSnackbar(UpdatePassword));
