import { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const tagsStyles = () => css`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
  .icon-plus-cnt {
    cursor: pointer;
    position: absolute;
    right: -30px;
  }
`;
