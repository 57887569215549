import axios from 'axios';
import { URL } from './configApi';
import {
  STATISTICS_GET_COMPAIGNS_FAILED,
  STATISTICS_GET_COMPAIGNS_SUCCEED,
  STATISTICS_GET_STATS_SUCCEED,
  STATISTICS_GET_STATS_FAILED,
  AppThunk,
} from './types';

export const statisticsGetCompaigns =
  (departmentId: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${URL}/campagnes/department/${departmentId}`)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          dispatch({
            type: STATISTICS_GET_COMPAIGNS_SUCCEED,
            payload: { [departmentId]: data.response.data.campagnes },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: STATISTICS_GET_COMPAIGNS_FAILED,
            payload: 'Network Error',
          });
        }
      });
  };

export const statisticsGetStats =
  (depatmentId: string, compaignId: string): AppThunk =>
  (dispatch) => {
    const endpoint = `/stats/campaigns/${compaignId}?departmentId=${depatmentId}`;
    axios
      .get(`${URL}${endpoint}`)
      .then(({ data }: { data: any }) => {
        if (data.success) {
          dispatch({
            type: STATISTICS_GET_STATS_SUCCEED,
            payload: { [compaignId || 'all']: data.success?.[0] },
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          dispatch({
            type: STATISTICS_GET_STATS_FAILED,
            payload: 'Network Error',
          });
        }
      });
  };
