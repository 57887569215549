import React from 'react';
import { connect } from 'react-redux';
// Material
import { CircularProgress, useRadioGroup } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// Styles
import styled from 'styled-components';
import { managerProfileStyles, useStyles } from './managerProfile.styles';
// Types
import { ManagerProfileFormProps } from './managerProfile.types';
// Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
// Components
import SideBar from '../../components/Drawer';
import UploadImage from '../../components/UploadImage';
// Actions
import {
  managerProfileGetCurrentManager,
  managerProfilePropsChanged,
  managerProfileConfirm,
  managerProfileIntialState,
} from '../../actions/managerProfileActions';

// Reducers
import { RootState } from '../../reducers';
// Utils
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from '../../utils/storage';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const ManagerProfile: React.FC<any> = (props) => {
  const classes = useStyles();

  const user = getItemFromLocalStorage('user');
  const {
    firstName,
    lastName,
    email,
    address,
    country,
    codePostal,
    phoneNumber,
    mobile,
    photo,
    firstNameError,
    lastNameError,
    emailError,
    messageError,
    loading,
    loadingPage,
  } = props;

  React.useEffect(() => {
    props.managerProfileGetCurrentManager();
    /* eslint-disable-next-line */
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.managerProfilePropsChanged('photo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.managerProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleClickUpdateProfile = () => {
    props.managerProfileConfirm(props, () =>
      props.snackbarShowMessage('le profil est modifié avec succès')
    );
  };

  // if (loadingPage) {
  //   return (
  //     <SideBar>
  //       <Wrapper>
  //         <div className='card'>
  //           <CircularProgress className={classes.circularProgress} />
  //         </div>
  //       </Wrapper>
  //     </SideBar>
  //   );
  // }

  return (
    <SideBar>
      <Wrapper>
        <div className='card'>
          {loadingPage ? (
            <CircularProgress className={classes.circularProgress} />
          ) : (
            <>
              <div className='main-header'>
                <UploadImage
                  image={photo}
                  emptyImage={`${user.firstName
                    .charAt(0)
                    .toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`}
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />

                <Button
                  height={30}
                  margin={10}
                  background='#FF7E04'
                  onClick={() => {
                    console.log('contact support');
                  }}
                >
                  {`${user.firstName} ${user.lastName}`}
                </Button>
              </div>
              <div className='main-content'>
                <Text
                  fontWeight={600}
                  fontSize={14}
                  lineHeight={21}
                  color='#373737'
                >
                  Informations générals
                </Text>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Input
                        value={firstName}
                        type='text'
                        name='firstName'
                        placeholder='Nom'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                      {firstNameError && <Text error>{firstNameError}</Text>}
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={lastName}
                        type='text'
                        name='lastName'
                        placeholder='Prénom'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                      {lastNameError && <Text error>{lastNameError}</Text>}
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        value={email}
                        type='text'
                        name='email'
                        placeholder='Email'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                      {emailError && <Text error>{emailError}</Text>}
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={address}
                        type='text'
                        name='address'
                        placeholder='Adresse'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        value={country}
                        type='text'
                        name='country'
                        placeholder='Ville'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        value={codePostal}
                        type='text'
                        name='codePostal'
                        placeholder='Code postal'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={phoneNumber}
                        type='text'
                        name='phoneNumber'
                        placeholder='Numéro de téléphone'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={mobile}
                        type='text'
                        name='mobile'
                        placeholder='Mobile'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {messageError && <Text error>{messageError}</Text>}
                    </Grid>
                  </Grid>
                </div>
                <div className='main-footer'>
                  <Button
                    onClick={handleClickUpdateProfile}
                    width={180}
                    margin={10}
                  >
                    {loading ? (
                      <CircularProgress size={20} color='inherit' />
                    ) : (
                      'Enregistrer'
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => managerProfileStyles()}
`;

const mapStateToProps = ({ managerProfile }: RootState) => ({
  firstName: managerProfile.firstName,
  lastName: managerProfile.lastName,
  email: managerProfile.email,
  address: managerProfile.address,
  country: managerProfile.country,
  codePostal: managerProfile.codePostal,
  phoneNumber: managerProfile.phoneNumber,
  mobile: managerProfile.mobile,
  photo: managerProfile.photo,
  firstNameError: managerProfile.firstNameError,
  lastNameError: managerProfile.lastNameError,
  emailError: managerProfile.emailError,
  messageError: managerProfile.messageError,
  loading: managerProfile.loading,
  loadingPage: managerProfile.loadingPage,
});

export const connector = connect(mapStateToProps, {
  managerProfileGetCurrentManager,
  managerProfilePropsChanged,
  managerProfileConfirm,
  managerProfileIntialState,
});

export default connector(withSnackbar(ManagerProfile));
