import {
  REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_SUCCESS,
  REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_FAILED,
  REGION_DEPARTMENT_DETAIL_INITIAL_STATE,
} from '../actions/types';
import {
  RegionDepartmentDetailState,
  RegionDepartmentDetailActionTypes,
} from '../modules/RegionDepartmentDetail/regionDepartmentDetail.types';

const INITIAL_STATE: RegionDepartmentDetailState = {
  name: '',
  logo: '',
  headOffice: '',
  president: null,
  vicePresident: null,
  responsibles: [],
  messageError: '',
  loadingPage: true,
};

export default (
  state = INITIAL_STATE,
  action: RegionDepartmentDetailActionTypes
): RegionDepartmentDetailState => {
  switch (action.type) {
    case REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingPage: false,
      };

    case REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loadingPage: false,
      };

    case REGION_DEPARTMENT_DETAIL_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
