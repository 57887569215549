import { css } from 'styled-components';

export const addIntervenerStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 850px;

  .main-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  .main-content {
    width: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .main-footer {
    display: flex;
    align-self: flex-end;
    margin-top: 10px;
  }
`;
