import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
//Styles
import { ic_people } from 'react-icons-kit/md/ic_people';
import { useStyles } from './intervenersRouter.styles.';
//Icons
//Components
import SideBar from '../../components/Drawer';
//Modules
import Interveners from '../../modules/Interveners';
import Intervener from '../../modules/IntervenerProfile';
import NotFound from '../../modules/NotFound';

const IntervenersRouter: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title='Intervenants' icon={ic_people}>
        <Switch>
          <Route path='/interveners' component={Interveners} exact />
          <Route path='/interveners/add-intervener' component={Intervener} />
          <Route path='/interveners/:intervenerId' component={Intervener} />
          <Route path='/interveners/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default IntervenersRouter;
