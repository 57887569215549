import {
  RESET_PASSWORD_PROPS_CHANGED,
  RESET_PASSWORD_PROPS_VALIDATION,
  RESET_PASSWORD_VERIFICATION_TOKEN_FAILED,
  RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_INITIAL_STATE,
} from '../actions/types';
import {
  ResetPasswordState,
  ResetPasswordActionTypes,
} from '../modules/ResetPassword/resetPassword.types';

const INITIAL_STATE: ResetPasswordState = {
  password: '',
  passwordConfirm: '',
  passwordError: '',
  passwordConfirmError: '',
  messageError: '',
  tokenVerficationError: '',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: ResetPasswordActionTypes
): ResetPasswordState => {
  switch (action.type) {
    case RESET_PASSWORD_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case RESET_PASSWORD_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case RESET_PASSWORD_VERIFICATION_TOKEN_FAILED:
      return {
        ...state,
        tokenVerficationError: action.payload,
        loadingPage: false,
      };

    case RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS:
      return { ...state, loadingPage: false };

    case RESET_PASSWORD_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false };

    case RESET_PASSWORD_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
