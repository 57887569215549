import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Calendar,
  Event,
  momentLocalizer,
  ToolbarProps,
} from 'react-big-calendar';
import moment from 'moment';
// Material
import { Box, IconButton, Paper, Chip } from '@material-ui/core';

//Icons
import { Icon } from 'react-icons-kit';
import { ic_view_list } from 'react-icons-kit/md/ic_view_list';
import { ic_control_point } from 'react-icons-kit/md/ic_control_point';
import { calendar } from 'react-icons-kit/fa/calendar';
import { ic_view_module } from 'react-icons-kit/md/ic_view_module';

import {
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
} from '@material-ui/icons';
// Styles
import './styles.sass';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { campagnesStyles } from './campagnes.styles';
// Types
import { CampagnesListProps, ChipData } from './campagnes.types';
// Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
// Components
import EmptyData from '../../components/EmptyData';
import Table from '../../components/Tables/CampagnesTable';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';

// Modules

// Assets/Images
import emptyState from '../../assets/images/emptyState.svg';
import Tags from '../../components/Tags';
import CampagneDetail from '../CampagneDetail';
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.

// Actions
import {
  campagnesGetAllCampagnes,
  campagnesGetAllInterventions,
  campagnesPropsChanged,
  campagnesGetFilterCampagnes,
  camapgnesDeleteCampagne,
  camapgnesDeleteIntervention,
  campagnesInitialState,
} from '../../actions/campagnesActions';
// Reducers
import { RootState } from '../../reducers';
import AddIntervention from '../AddIntervention';
// Utils
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const localizer = momentLocalizer(moment);

const now = new Date();

const myEventsList = [
  {
    id: 1,
    title: 'All Day Event very long title',
    allDay: true,
    start: moment().toDate(),
    end: moment().add('days', 3).toDate(),
  },
  {
    id: 2,
    title: 'All Day',
    allDay: true,
    start: moment().toDate(),
    end: moment().add('days', 3).toDate(),
  },
  {
    id: 3,
    title: 'Event very long title',
    allDay: true,
    start: moment().toDate(),
    end: moment().add('days', 3).toDate(),
  },
];

const Campagnes: React.FC<any> = (props) => {
  const {
    campagnes,
    campagnesFilter,
    interventions,
    search,
    messageError,
    loading,
  } = props;
  const [Currentmonth, setCurrentMonth] = React.useState(new Date());
  const [changeView, setChangeView] = React.useState<string>('calendar');
  const [page, setPage] = React.useState(0);
  const [limit, setlimitPage] = React.useState(10);
  const [campagneId, setCampagneId] = React.useState('');
  const [interventionId, setInterventionId] = React.useState('');
  const [interveners, setInterveners] = React.useState([]);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [openDialogDeleteIntervention, setOpenDialogDeleteIntervention] =
    React.useState(false);

  const [openCampagneDetail, setOpenCampagneDetail] =
    React.useState<boolean>(false);
  const [openAddInterventionType, setOpenAddInterventionType] =
    React.useState<boolean>(false);

  const classes = styles();
  React.useEffect(() => {
    // props.usersGetAllUsers(page, limit);
    props.campagnesGetAllCampagnes();
    props.campagnesGetAllInterventions();
    /* eslint-disable-next-line */
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setlimitPage(+event.target.value);
    setPage(0);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.campagnesGetFilterCampagnes(event.target.value, campagnesFilter);
    // props.usersPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSearch = () => {
    props.campagnesGetFilterCampagnes(search, campagnesFilter);
  };
  const onSelectEvent = (event: Event) => {
    props.history.push(`/campagnes/${event.resource.id}`);
  };

  const handleClickDelete = () => {
    props.camapgnesDeleteCampagne(campagneId, campagnes, () => {
      props.snackbarShowMessage('Campagne est supprimé avec succès');
      setOpenDialogDelete(false);
    });
  };

  const handleDeleteIntervention = () => () => {
    props.camapgnesDeleteIntervention(interventionId, interventions, () => {
      props.snackbarShowMessage('Intervention est supprimé avec succès');
      setOpenDialogDeleteIntervention(false);
    });
  };

  const handleClickOpenDialog = (campagneId: string) => {
    setCampagneId(campagneId);
    setOpenDialogDelete(true);
  };

  const handleClickCloseDialog = () => {
    setOpenDialogDelete(false);
  };

  const componentsToolbar = {
    // eslint-disable-next-line react/display-name
    toolbar: (props: ToolbarProps) => (
      <ToolBar
        {...props}
        changeCurrentMonth={(value: Date) => setCurrentMonth(value)}
      />
    ),
  };
  let iconColorCalendar = '#C0C0C0';
  let iconColorList = '#C0C0C0';
  if (changeView === 'calendar') {
    iconColorCalendar = '#FF7E04';
    iconColorList = '#C0C0C0';
  } else {
    iconColorCalendar = '#C0C0C0';
    iconColorList = '#FF7E04';
  }

  return (
    <Wrapper>
      <div className='main-header'>
        <div className='div-input-search'>
          <Input
            value={search}
            type='text'
            name='search'
            placeholder='Rechercher'
            width={1000}
            margin='10px 0'
            className='input-search'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEventChange(e)
            }
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#373737'
            className='button-search'
          >
            Rechercher
          </Button>
        </div>
        <Button
          onClick={() => props.history.push('/campagnes/add-campagne')}
          width={150}
          margin={10}
        >
          Ajouter campagne
        </Button>
      </div>

      <div className='header-list'>
        <Text
          fontWeight={600}
          fontSize={16}
          lineHeight={24}
          // style={{ width: '1000px', marginTop: '30px' }}
        >
          Liste des campagnes
        </Text>
        <div className='div-icons'>
          <div
            role='button'
            tabIndex={0}
            className='icon-content'
            style={{ marginRight: '10px' }}
            onClick={() => {
              setChangeView('calendar');
            }}
          >
            <Icon
              icon={ic_view_module}
              size='30'
              style={{ color: iconColorCalendar }}
            />
          </div>
          <div
            role='button'
            tabIndex={0}
            className='icon-content'
            onClick={() => {
              setChangeView('list');
            }}
          >
            <Icon
              icon={ic_view_list}
              size='30'
              style={{ color: iconColorList }}
            />
          </div>
        </div>
      </div>
      <Modal
        open={openCampagneDetail}
        onClose={() => setOpenCampagneDetail(!openCampagneDetail)}
      >
        <CampagneDetail campagneId={campagneId} />
      </Modal>

      <Modal
        open={openAddInterventionType}
        onClose={() => setOpenAddInterventionType(!openAddInterventionType)}
      >
        <AddIntervention
          onSaveChanged={() =>
            setOpenAddInterventionType(!openAddInterventionType)
          }
        />
      </Modal>

      <div className='card'>
        {changeView === 'calendar' ? (
          <Calendar
            selectable
            localizer={localizer}
            events={props.events}
            views={['month']}
            defaultDate={Currentmonth}
            startAccessor='start'
            endAccessor='end'
            components={componentsToolbar}
            onSelectEvent={onSelectEvent}
            style={{ height: 650 }}
            onShowMore={(events, date) => console.log(events)}
          />
        ) : (
          <EmptyData
            data={campagnes}
            loading={loading}
            message='La liste des campagnes est vide'
            messageError=''
            image={emptyState}
          >
            <Dialog
              open={openDialogDelete}
              subject='Supprimer campagne'
              content='Confirmez-vous la suppresion?'
              agreeText='Oui'
              cancelText='Non'
              handleAgree={handleClickDelete}
              handleClose={handleClickCloseDialog}
            />
            <Table
              data={campagnes}
              count={campagnes.length}
              rowsPerPage={limit}
              page={page}
              changePage={handleChangePage}
              changeRowsPerPage={handleChangeRowsPerPage}
              clickDetail={(campagneId) => {
                setOpenCampagneDetail(!openCampagneDetail);
                setCampagneId(campagneId);
              }}
              clickEdit={(campagneId) => {
                console.log('campagneId', campagneId);
              }}
              clickDelete={(campagneId) => {
                handleClickOpenDialog(campagneId);
              }}
            />
          </EmptyData>
        )}
      </div>
      <Dialog
        open={openDialogDeleteIntervention}
        subject='Supprimer intervention'
        content='Confirmez-vous la suppresion?'
        agreeText='Oui'
        cancelText='Non'
        handleAgree={handleDeleteIntervention()}
        handleClose={() => {
          setOpenDialogDeleteIntervention(false);
        }}
      />
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ width: '1200px', marginTop: '20px' }}
      >
        Interventions
      </Text>
      <div className='card-2'>
        <Paper component='ul' className={classes.root}>
          {interventions.map((item: any) => (
            <li key={item._id}>
              <Chip
                label={item.name}
                onDelete={() => {
                  setInterventionId(item._id);
                  setOpenDialogDeleteIntervention(true);
                }}
                className={classes.chip}
              />
            </li>
          ))}
        </Paper>
        <div
          role='button'
          tabIndex={0}
          style={{ cursor: 'pointer', margin: '12px' }}
          onClick={() => {
            setOpenAddInterventionType(!openAddInterventionType);
          }}
        >
          <Icon
            icon={ic_control_point}
            size='25'
            style={{ color: '#373737' }}
          />
        </div>
        {/* <div
          style={{
            display: 'flex',
            flex: 1,
            border: '1px solid #C0C0C0',
            borderRadius: '20px',
          }}
        >
          {interventions.map((item: any) => {
            console.log('item', item);
          })} */}

        {/* <Tags
              multiple
              options={[]}
              tags={interventions}
              placeholder='Type intervention'
              getTags={(interventions) => {}}
              handleClickAdd={() => {
                setOpenAddInterventionType(!openAddInterventionType);
              }}
            ></Tags> */}
        {/* </div> */}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => campagnesStyles()}
`;

const styles = makeStyles((theme) => ({
  containerCalendar: {
    width: '56%',
    margin: '0px auto',
    background: 'white',
    boxShadow: 'rgb(240, 246, 251) 0px 0px 10px 5px',
    borderRadius: '5px',
    marginRight: '20px',
    height: '550px',
    padding: '2% 2% 0% 2%',
    position: 'relative',
  },
  mainContainer: {
    width: '40%',
  },
  containerSchedule: {
    width: '100%',
    margin: '0px auto',
    background: 'white',
    boxShadow: 'rgb(240, 246, 251) 0px 0px 10px 5px',
    borderRadius: '5px',
    height: 'fit-content',
  },
  tabsContainer: {
    borderRadius: '5px',
    border: '1px solid rgb(232, 240, 247)',
    padding: '15px',
    display: 'grid',
    gridTemplateColumns: '10% 30% 20% 40%',
    marginBottom: '15px',
  },
  tabsHolidayContainer: {
    borderRadius: '5px',
    border: '1px solid rgb(232, 240, 247)',
    padding: '15px',
    display: 'grid',
    gridTemplateColumns: '20% 20% 60%',
    marginBottom: '15px',
  },
  span: {
    margin: 'auto 0 auto 10px',
    fontSize: '13px',
  },
  modal: {
    maxWidth: '350px',
    minWidth: '350px',
    padding: '20px 25px',
    background: 'white',
    boxShadow: '3px 3px 15px 2px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
    margin: 'auto',
    zIndex: 9999,
    outline: 'none',
  },
  textarea: {
    background: 'aliceblue',
    width: 'calc(100% - 20px)',
    margin: '0 6px 15px',
    borderRadius: '5px',
    border: 0,
    fontFamily: 'avenir',
    fontSize: '14px',
    padding: '15px 10px',
  },
  emptyState: {
    color: '#bdbdbd',
    margin: '20px 0',
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
  },
  root: {
    display: 'flex',
    flex: 1,
    border: '1px solid #C0C0C0',
    borderRadius: '20px',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: 'none',
    // marginRight: '12px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

type ToolbarStyledProps = ToolbarProps & {
  changeCurrentMonth: (value: Date) => void;
};

const ToolBar: React.FC<ToolbarStyledProps> = (props) => {
  useEffect(() => {
    props.changeCurrentMonth(props.date);
  }, [props, props.date]);
  return (
    <Box width={1} display='flex' justifyContent='space-between'>
      <Box
        width={1}
        display='flex'
        justifyContent='space-evenly'
        alignItems='center'
        style={{ margin: '15px 0 25px 0' }}
      >
        <IconButton
          style={{ backgroundColor: '#dd0089' }}
          size='small'
          color='secondary'
          aria-label='back'
          onClick={() => {
            props.onNavigate('PREV');
          }}
        >
          <KeyboardArrowLeftRounded style={{ color: 'white' }} />
        </IconButton>
        <span style={{ width: '130px', textAlign: 'center' }}>
          {props.label}
        </span>
        <IconButton
          style={{ backgroundColor: '#dd0089' }}
          size='small'
          color='secondary'
          aria-label='next'
          onClick={() => {
            props.onNavigate('NEXT');
          }}
        >
          <KeyboardArrowRightRounded style={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Button
        style={{ height: '35px', marginTop: '9px' }}
        onClick={() => props.onNavigate('TODAY')}
      >
        <span style={{ paddingTop: '2px', textTransform: 'capitalize' }}>
          {props.localizer.messages.today}
        </span>
      </Button>
    </Box>
  );
};

const mapStateToProps = ({ campagnes }: RootState) => ({
  campagnes: campagnes.campagnes,
  campagnesFilter: campagnes.campagnesFilter,
  interventions: campagnes.interventions,
  events: campagnes.events,
  search: campagnes.search,
  messageError: campagnes.messageError,
  loading: campagnes.loading,
});

export const connector = connect(mapStateToProps, {
  campagnesGetAllCampagnes,
  campagnesGetAllInterventions,
  campagnesPropsChanged,
  campagnesGetFilterCampagnes,
  camapgnesDeleteCampagne,
  camapgnesDeleteIntervention,
  campagnesInitialState,
});

export default connector(withSnackbar(Campagnes));
