import axios from 'axios';
import { URL } from './configApi';
import {
  CAMPAGNE_GET_All_LIST_SUCCESS,
  CAMPAGNE_GET_All_LIST_FAILED,
  CAMPAGNE_PROFILE_GET_CAMPAGNE_SUCCESS,
  CAMPAGNE_PROFILE_GET_CAMPAGNE_FAILED,
  CAMPAGNE_PROFILE_PROPS_CHANGED,
  CAMPAGNE_PROFILE_PROPS_VALIDATION,
  CAMPAGNE_PROFILE_GET_CAMPAGNE_SUMMARY,
  CAMPAGNE_PROFILE_CREATE_CAMPAGNE_SUCCESS,
  CAMPAGNE_PROFILE_CREATE_CAMPAGNE_FAILED,
  CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_SUCCESS,
  CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_FAILED,
  CAMPAGNE_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  CampagneProfileState,
  CampagneProfilePropsChangedAction,
  CampagneProfileInitialStateAction,
} from '../modules/CampagneProfile/campagneProfile.types';
import {
  UserApiResponse,
  GetUsersApiResponse,
  DepartmentApiResponse,
} from './typesApi';
// Utils
import {
  campagneProfileGetCampagneObject,
  validateFieldsCampagneProfile,
  campagneProfileObject,
  campagneSummaryObject,
} from '../modules/CampagneProfile/campagneProfile.utils';

export const getAllList =
  (userId: string): AppThunk =>
  (dispatch) => {
    const promise1 = axios.get(`${URL}/users?permission[eq]=read/write`);
    const promise2 = axios.get(
      `${URL}/users?permission=volunteer&permission=professional`
    );
    const promise3 = axios.get(
      `${URL}/region_department/departments/${userId}`
    );
    const promise4 = axios.get(`${URL}/interventions`);

    let organizers: UserApiResponse[] = [];
    let interveners: UserApiResponse[] = [];
    let departments: DepartmentApiResponse[] = [];
    let interventions: any[] = [];

    Promise.all([promise1, promise2, promise3, promise4])
      .then((values: any) => {
        organizers = [
          ...values[0].data.response.data.map((user: UserApiResponse) => ({
            _id: user._id,
            name: `${user.firstName} ${user.lastName}`,
          })),
        ];
        interveners = [
          ...values[1].data.response.data.map((user: UserApiResponse) => ({
            _id: user._id,
            name: `${user.firstName} ${user.lastName}`,
          })),
        ];

        departments = [
          ...values[2].data.response.data.map((department: any) => ({
            _id: department._id,
            name: department.name,
          })),
        ];

        interventions = [
          ...values[3].data.response.data.map((intervention: any) => ({
            _id: intervention._id,
            name: intervention.name,
          })),
        ];

        dispatch({
          type: CAMPAGNE_GET_All_LIST_SUCCESS,
          allOrganizersList: organizers,
          allIntervenersList: interveners,
          allInterventionsList: interventions,
          allDepartmentsList: departments,
        });
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          dispatch({
            type: CAMPAGNE_GET_All_LIST_FAILED,
            payload: 'Network Error',
          });
        }
      });
  };

export const campagneProfileGetCampagne =
  (campagneId: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${URL}/campagnes/${campagneId}`)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          const campagne = campagneProfileGetCampagneObject(data.response.data);

          dispatch({
            type: CAMPAGNE_PROFILE_GET_CAMPAGNE_SUCCESS,
            payload: campagne,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: CAMPAGNE_PROFILE_GET_CAMPAGNE_FAILED,
            payload: 'Campagne introuvable.',
          });
        }
      });
  };

export const campagneProfilePropsChanged = (
  prop: string,
  value: any
): CampagneProfilePropsChangedAction => ({
  type: CAMPAGNE_PROFILE_PROPS_CHANGED,
  prop,
  value,
});

export const campagneProfileCreateCampagne =
  (props: CampagneProfileState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsCampagneProfile(props);
    dispatch({ type: CAMPAGNE_PROFILE_PROPS_VALIDATION, payload: newProps });
    const campagne = campagneSummaryObject(newProps);

    if (valid && campagne) {
      dispatch({
        type: CAMPAGNE_PROFILE_GET_CAMPAGNE_SUMMARY,
        payload: campagne,
      });
      callback();
    }
  };

export const campagneProfileConfirmCreateCampagne =
  (campagneSummary: any, callback: () => void): AppThunk =>
  (dispatch) => {
    // const { newProps, valid } = validateFieldsCampagneProfile(props);
    // dispatch({ type: CAMPAGNE_PROFILE_PROPS_VALIDATION, payload: newProps });
    const campagne = campagneProfileObject(campagneSummary);

    if (campagne) {
      dispatch({
        type: CAMPAGNE_PROFILE_PROPS_CHANGED,
        prop: 'loadingConfirm',
        value: true,
      });

      axios
        .post(`${URL}/campagnes`, campagne)
        .then(({ data }: { data: any }) => {
          if (data.status === 'success') {
            dispatch({
              type: CAMPAGNE_PROFILE_CREATE_CAMPAGNE_SUCCESS,
            });
            callback();
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            dispatch({
              type: CAMPAGNE_PROFILE_CREATE_CAMPAGNE_FAILED,
              payload: 'Campagne existe déjà.',
            });
          }
        });
    }
  };

export const campagneProfileUpdateCampagne =
  (
    campagneId: string,
    props: CampagneProfileState,
    callback: () => void
  ): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsCampagneProfile(props);
    dispatch({ type: CAMPAGNE_PROFILE_PROPS_VALIDATION, payload: newProps });
    const campagne = campagneProfileObject(newProps);

    if (valid && campagne) {
      dispatch({
        type: CAMPAGNE_PROFILE_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });

      axios
        .put(`${URL}/campagnes/${campagneId}`, campagne)
        .then(({ data }: { data: any }) => {
          if (data.status === 'success') {
            setTimeout(() => {
              dispatch({
                type: CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_SUCCESS,
                payload: campagneProfileGetCampagneObject(data.response.data),
              });
            }, 1500);

            callback();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            dispatch({
              type: CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_FAILED,
              payload: 'Campagne introuvable.',
            });
          }
        });
    }
  };

export const campagneProfileIntialState =
  (): CampagneProfileInitialStateAction => ({
    type: CAMPAGNE_PROFILE_INITIAL_STATE,
  });
