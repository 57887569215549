import {
  ADD_INTERVENTION_PROPS_CHANGED,
  ADD_INTERVENTION_FAILED,
  ADD_INTERVENTION_INITIAL_STATE,
} from '../actions/types';
import {
  AddInterventionState,
  AddInterventionActionTypes,
} from '../modules/AddIntervention/addIntervention.types';

const INITIAL_STATE: AddInterventionState = {
  intervention: '',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: AddInterventionActionTypes
): AddInterventionState => {
  switch (action.type) {
    case ADD_INTERVENTION_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case ADD_INTERVENTION_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case ADD_INTERVENTION_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
