import axios from 'axios';
import { URL } from './configApi';
import {
  REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_SUCCESS,
  REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_FAILED,
  REGION_DEPARTMENT_DETAIL_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  RegionDepartmentDetailState,
  RegionDepartmentDetailInitialStateAction,
} from '../modules/RegionDepartmentDetail/regionDepartmentDetail.types';

// Utils
import { regionDepartmentProfileGetRegionDepartmentObject } from '../modules/RegionDepartmentDetail/regionDepartmentDetail.utils';

export const regionDepartmentDetailGetRegionDepartment =
  (regionDepartmentId: string, regionDepartmentType: string): AppThunk =>
  (dispatch) => {
    let route = null;
    if (regionDepartmentType === 'region') {
      route = `region_department/${regionDepartmentId}/departments`;
    } else if (regionDepartmentType === 'department') {
      route = `region_department/${regionDepartmentId}`;
    }

    axios
      .get(`${URL}/${route}`)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          const regionDepartment =
            regionDepartmentProfileGetRegionDepartmentObject(
              data.response.data[0]
            );

          dispatch({
            type: REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_SUCCESS,
            payload: regionDepartment,
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          dispatch({
            type: REGION_DEPARTMENT_DETAIL_GET_REGION_DEPARTMENT_FAILED,
            payload: 'region/departement introuvable.',
          });
        }
      });
  };

export const regionDepartmentDetailInitialState =
  (): RegionDepartmentDetailInitialStateAction => ({
    type: REGION_DEPARTMENT_DETAIL_INITIAL_STATE,
  });
