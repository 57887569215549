import React from 'react';
import { connect } from 'react-redux';
// Material
import { CircularProgress } from '@material-ui/core';
// Style
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';
import { resetPasswordStyles, useStyles } from './resetPassword.styles';
// Types
import { ResetPasswordFormProps } from './resetPassword.types';
// Commomns
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Icons

// Actions
import {
  resetPasswordverificationToken,
  resetPasswordPropsChanged,
  resetPasswordConfirm,
  resetPasswordIntialState,
} from '../../actions/resetPasswordActions';

// Reducers
import { RootState } from '../../reducers';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const ResetPassword: React.FC<any> = (props) => {
  const [visiblePassword, setVisiblePassword] = React.useState<boolean>(false);
  const [visiblePasswordConfirm, setVisiblePasswordConfirm] =
    React.useState<boolean>(false);

  const classes = useStyles();
  const {
    password,
    passwordConfirm,
    passwordError,
    passwordConfirmError,
    messageError,
    tokenVerficationError,
    loading,
    loadingPage,
  } = props;

  React.useEffect(() => {
    const { token } = props.match.params;
    props.resetPasswordverificationToken(token);
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    props.resetPasswordIntialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.resetPasswordPropsChanged(event.target.name, event.target.value);
  };

  const handleClickResetPassword = () => {
    const { token } = props.match.params;
    props.resetPasswordConfirm(token, props, () => {
      props.snackbarShowMessage(
        'Votre mot de passe a été mis à jour avec succès, veuillez vous connecter'
      );
      setTimeout(() => {
        props.history.push('/login');
      }, 3000);
    });
  };

  if (loadingPage) {
    return (
      <div className={classes.root}>
        <CircularProgress size={30} className={classes.circularProgress} />
      </div>
    );
  }
  if (tokenVerficationError) {
    return (
      <Wrapper>
        <Text error fontSize={24}>
          {tokenVerficationError}
        </Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className='card'>
        <Text fontWeight={600} fontSize={24} lineHeight={36}>
          RÉINITIALISER MOT DE PASSE
        </Text>
        <div className='main-content'>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              value={password}
              type={visiblePassword ? 'text' : 'password'}
              name='password'
              placeholder='Nouveau mot de passe'
              width={360}
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            <Icon
              onClick={() => setVisiblePassword(!visiblePassword)}
              icon={visiblePassword ? ic_visibility_off : ic_remove_red_eye}
              size='24'
              style={{
                position: 'absolute',
                right: 10,
                color: '#c0c0c0',
                cursor: 'pointer',
              }}
            />
          </div>
          {passwordError && <Text error>{passwordError}</Text>}
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              value={passwordConfirm}
              type={visiblePasswordConfirm ? 'text' : 'password'}
              name='passwordConfirm'
              placeholder='Confirmez le nouveau mot de passe'
              width={360}
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            <Icon
              onClick={() => setVisiblePasswordConfirm(!visiblePasswordConfirm)}
              icon={
                visiblePasswordConfirm ? ic_visibility_off : ic_remove_red_eye
              }
              size='24'
              style={{
                position: 'absolute',
                right: 10,
                color: '#c0c0c0',
                cursor: 'pointer',
              }}
            />
          </div>
          {passwordConfirmError && <Text error>{passwordConfirmError}</Text>}
        </div>

        {messageError && <Text error>{messageError}</Text>}

        <Button onClick={handleClickResetPassword} width={210} margin={10}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Enregistrer'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => resetPasswordStyles()}
`;

const mapStateToProps = ({ resetPassword }: RootState) => ({
  password: resetPassword.password,
  passwordConfirm: resetPassword.passwordConfirm,
  passwordError: resetPassword.passwordError,
  passwordConfirmError: resetPassword.passwordConfirmError,
  tokenVerficationError: resetPassword.tokenVerficationError,
  messageError: resetPassword.messageError,
  loading: resetPassword.loading,
  loadingPage: resetPassword.loadingPage,
});

export const connector = connect(mapStateToProps, {
  resetPasswordverificationToken,
  resetPasswordPropsChanged,
  resetPasswordConfirm,
  resetPasswordIntialState,
});

export default connector(withSnackbar(ResetPassword));
