import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from '@material-ui/core';
import { VictoryPie, VictoryLabel } from 'victory';
//Styles
import styled from 'styled-components';
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard';
import { PDFDownloadLink } from '@react-pdf/renderer';
//Icons
import { O_APPEND } from 'constants';
import SideBar from '../../components/Drawer';
//Commons
import Text from '../../commons/Text';
import { generalInfosGetInfos } from '../../actions/generalInfosActions';
import {
  statisticsGetCompaigns,
  statisticsGetStats,
} from '../../actions/statisticsActions';
import { RootState } from '../../reducers';

// Utils
import { getItemFromLocalStorage } from '../../utils/storage';
import { groupByKey } from './statistics.utils';
import { statisticsStyles, LegendMark } from './statistics.styles';
import StatisticsPDF from './StatisticsPDF';

type Key = 'protein' | 'glucose' | 'blood';
type FilterBy = 'all' | 'male' | 'female';
type Filter = Record<Key, FilterBy>;
type KeyWords = Record<Key, string>;

const keyWords: KeyWords = {
  blood: 'Sang',
  glucose: 'Glucose',
  protein: 'Protéine',
};

const colors = ['#FFA604', '#FF6362', '#BC5090', '#58508D', '#00405C'];
const Statistics: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const user = getItemFromLocalStorage('user');

  const [selectedRegion, setSelectedRegion] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [filter, setFilter] = useState<Filter>({
    glucose: 'all',
    protein: 'all',
    blood: 'all',
  });

  const { generalInfos, statistics } = useSelector((state: RootState) => state);
  const regions = useMemo(
    () =>
      generalInfos.infos.map((item) => ({
        _id: item._id,
        name: item.name,
        departments: item.departments,
      })),
    [generalInfos]
  );
  const departments = useMemo(
    () => regions.find((item) => item._id === selectedRegion)?.departments,
    [regions, selectedRegion]
  );

  const compaigns = useMemo(
    () => statistics?.departments?.[selectedDepartment] || [],
    [statistics]
  );

  const stats = useMemo(
    () => statistics?.stats?.[selectedCampaign] || null,
    [statistics]
  );
  const statsGrouped = useMemo<any>(() => {
    if (stats) {
      const arr = ['protein', 'blood', 'glucose'];
      return arr.reduce((result, key) => {
        const arrGrouped = groupByKey(stats, key);
        return arrGrouped
          ? {
              ...result,
              [key]: arrGrouped,
            }
          : result;
      }, {});
    }
    return undefined;
  }, [stats]);
  // console.log(statsGrouped);
  useEffect(() => {
    selectedDepartment &&
      selectedCampaign &&
      dispatch(statisticsGetStats(selectedDepartment, selectedCampaign));
  }, [selectedCampaign, selectedDepartment]);

  useEffect(() => {
    user && dispatch(generalInfosGetInfos(user._id));
  }, []);

  useEffect(() => {
    selectedDepartment && dispatch(statisticsGetCompaigns(selectedDepartment));
  }, [selectedDepartment]);
  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (event.target.name === 'region') {
      setSelectedRegion(event.target.value);
    } else if (event.target.name === 'department') {
      setSelectedDepartment(event.target.value);
    } else {
      setSelectedCampaign(event.target.value);
    }
    // props.addUserPropsChanged('permission', event.target.value);
  };
  const handleChangeFilter = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name } = event.target;
    setFilter({
      ...filter,
      [name!]: event.target.value,
    });
  };

  const handleGender = (gender: string) => {
    if (gender === 'female') return 'Femmme';
    return 'Homme';
  };

  const renderLegend = (key: Key) => (
    <Box
      display='flex'
      flex={1}
      marginTop={4}
      paddingBottom={2}
      alignItems='center'
    >
      <Text color='#C0C0C0'>{keyWords[key]}:</Text>
      {true && (
        <>
          {statsGrouped[key]?.all[key].map((item: any, index: number) => {
            const text = item.x.replaceAll('_', ' ');
            return (
              <Box
                key={item.x}
                display='flex'
                alignItems='center'
                marginLeft={2}
                marginRight={2}
              >
                <LegendMark color={colors[index]} />
                <Text color='#C0C0C0'> {text} </Text>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
  const renderButtonPdfDocument = () => (
    <PDFDownloadLink
      document={
        <StatisticsPDF
          Region={regions?.find((o) => o._id === selectedRegion)?.name || ''}
          Department={
            departments?.find((o: any) => o._id === selectedDepartment)?.name ||
            ''
          }
          Companion={
            compaigns?.find((o: any) => o._id === selectedCampaign)?.name
          }
          countDocument={stats?.countDocument[0]?.total}
          gender={stats?.gender}
          age={stats?.age}
          statsGrouped={statsGrouped}
        />
      }
      fileName='fiche_number'
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '80px',
        color: '#fff',
        backgroundColor: '#373737',
        height: 'auto',
        textDecoration: 'none',
        borderRadius: '30px',
        padding: '12px 30px 12px 30px',
        marginTop: '12px',
        fontStyle: 'normal',
        fontSize: '12px',
      }}
    >
      Imprimer
    </PDFDownloadLink>
  );

  return (
    <SideBar title='Dashboard' icon={ic_dashboard}>
      <Wrapper>
        <div className='main-header'>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div style={{ marginTop: '10px' }}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='region'>Région</InputLabel>
                  <Select
                    value={selectedRegion}
                    name='region'
                    onChange={handleSelectChange}
                    label='Region'
                    id='region'
                    // inputProps={{ className: 'text-field-select' }}
                  >
                    {regions?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        <Text color='#373737'>{option.name}</Text>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ marginTop: '10px' }}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='department'>Département</InputLabel>
                  <Select
                    value={selectedDepartment}
                    name='department'
                    onChange={handleSelectChange}
                    label='Department'
                    id='department'
                    // inputProps={{ className: 'text-field-select' }}
                  >
                    {departments?.map((option: any) => (
                      <MenuItem key={option._id} value={option._id}>
                        <Text color='#373737'>{option.name}</Text>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ marginTop: '10px' }}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='compagne'>Compagne</InputLabel>
                  <Select
                    value={selectedCampaign}
                    name='compagne'
                    onChange={handleSelectChange}
                    label='Compagne'
                    id='compagne'
                    // inputProps={{ className: 'text-field-select' }}
                  >
                    {compaigns.length > 0 && (
                      <MenuItem value='ALL'>
                        <Text color='#373737'>All</Text>
                      </MenuItem>
                    )}
                    {compaigns.map((option: any) => (
                      <MenuItem key={option._id} value={option._id}>
                        <Text color='#373737'>{option.name}</Text>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            {stats && (
              <Grid item xs={2} alignItems='center'>
                <>{renderButtonPdfDocument()}</>
              </Grid>
            )}
          </Grid>
        </div>
        {!stats && (
          <Box display='flex' justifyContent='center' marginTop={6}>
            Vous devez choisir une compagne.
          </Box>
        )}
        <Box
          display='flex'
          flexDirection='column'
          marginTop={4}
          marginBottom={4}
        >
          {stats && (
            <Text fontSize={18} fontWeight={800}>
              Nombres des fiches enregistrés: &nbsp;
              <Text fontSize={26} fontWeight={800}>
                {stats?.countDocument[0]?.total || 0}
              </Text>
              &nbsp; Fiches
            </Text>
          )}

          <Box marginTop={3} display='flex' gridGap={25}>
            {stats?.gender?.map((gender: any) => (
              <Box
                key={gender.gender}
                display='flex'
                width={280}
                height={280}
                justifyContent='center'
                alignItems='center'
                borderRadius={20}
                style={{
                  background: '#fff',
                  boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.1)',
                }}
              >
                <svg viewBox='0 0 400 400'>
                  <VictoryPie
                    standalone={false}
                    width={400}
                    height={400}
                    data={[
                      { x: 'Femme', y: gender.percentage },
                      { x: '', y: 100 - gender.percentage },
                    ]}
                    innerRadius={120}
                    labelRadius={100}
                    style={{ labels: { fontSize: 0, fill: 'white' } }}
                    colorScale={['#EE33A8', '#F5F7FA']}
                  />

                  {/* <circle
                    cx='200'
                    cy='200'
                    r='155'
                    fill='none'
                    strokeWidth={2}
                  /> */}
                  <VictoryLabel
                    textAnchor='middle'
                    verticalAnchor='middle'
                    x={200}
                    y={200}
                    style={{ fontSize: 22, fontWeight: 800 }}
                    text={`${Math.round(gender.percentage)}% ${handleGender(
                      gender.gender
                    )}`}
                  />
                </svg>
              </Box>
            ))}
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' marginBottom={4}>
          {stats?.age && (
            <>
              <Text fontSize={18} fontWeight={800}>
                Tranche d'age
              </Text>
              <Box marginTop={3} display='flex' flexWrap='wrap' gridGap={25}>
                {stats?.age?.map((ageSection: any) => (
                  <Box
                    key={ageSection.age}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      display='flex'
                      width={180}
                      height={180}
                      justifyContent='center'
                      alignItems='center'
                      borderRadius={20}
                      marginBottom={1}
                      style={{
                        background: '#fff',
                        boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.1)',
                      }}
                    >
                      <svg viewBox='0 0 400 400'>
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          data={[
                            { x: ageSection.age, y: ageSection.percentage },
                            { x: '', y: 100 - ageSection.percentage },
                          ]}
                          innerRadius={120}
                          labelRadius={100}
                          style={{ labels: { fontSize: 10, fill: 'white' } }}
                          colorScale={['#EE33A8', '#F5F7FA']}
                        />
                        {/* <circle
                          cx='200'
                          cy='200'
                          r='155'
                          // fill='none'
                          strokeWidth={2}
                        /> */}
                        <VictoryLabel
                          textAnchor='middle'
                          verticalAnchor='middle'
                          x={200}
                          y={200}
                          style={{ fontSize: 32, fontWeight: 800 }}
                          text={`${Math.round(ageSection.percentage)}%`}
                        />
                      </svg>
                    </Box>
                    <Text fontWeight={500}>{ageSection.age}</Text>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
        {statsGrouped &&
          Object.entries(statsGrouped)?.map(([k, item]: [string, any]) => {
            const key = k as Key;
            return (
              <Fragment key={key}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  width='1000px'
                >
                  <Text fontSize={18} fontWeight={800}>
                    {keyWords[key]}
                  </Text>
                  <FormControl variant='outlined' className='filter-by'>
                    <InputLabel htmlFor='compagne'>By</InputLabel>
                    <Select
                      value={filter[key]}
                      name={key}
                      onChange={handleChangeFilter}
                      label='By'
                      id={key}
                    >
                      <MenuItem value='all'>
                        <Text color='#373737'>All</Text>
                      </MenuItem>
                      <MenuItem value='male'>
                        <Text color='#373737'>Homme</Text>
                      </MenuItem>
                      <MenuItem value='female'>
                        <Text color='#373737'>Femme</Text>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  width={1000}
                  marginBottom={4}
                  marginTop={4}
                  style={{ background: '#fff' }}
                >
                  <Box display='flex' flexWrap='wrap'>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      width={280}
                      height={280}
                    >
                      <svg viewBox='0 0 400 375'>
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          data={item.all?.[key]}
                          innerRadius={70}
                          labelRadius={100}
                          colorScale={colors}
                          labels={({ datum }) => datum.y && `${datum.y}%`}
                          style={{
                            labels: {
                              fill: 'white',
                              fontSize: 20,
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </svg>
                      <Text fontWeight={500}>Tous</Text>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      width={280}
                      height={280}
                    >
                      <svg viewBox='0 0 400 375'>
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          data={item.all?.[`${key} Homme`]}
                          innerRadius={70}
                          labelRadius={100}
                          colorScale={colors}
                          labels={({ datum }) => datum.y && `${datum.y}%`}
                          style={{
                            labels: {
                              fill: 'white',
                              fontSize: 20,
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </svg>
                      <Text fontWeight={500}>Hommes</Text>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      width={280}
                      height={280}
                    >
                      <svg viewBox='0 0 400 375'>
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          data={item.all?.[`${key} Femme`]}
                          innerRadius={70}
                          labelRadius={100}
                          colorScale={colors}
                          labels={({ datum }) => datum.y && `${datum.y}%`}
                          style={{
                            labels: {
                              fill: 'white',
                              fontSize: 20,
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </svg>
                      <Text fontWeight={500}>Femmes</Text>
                    </Box>
                    {item[filter[key]] && (
                      <>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          width={280}
                          height={280}
                        >
                          <svg viewBox='0 0 400 375'>
                            <VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              data={item[filter[key]][`${key}Inf18`]}
                              innerRadius={70}
                              labelRadius={100}
                              colorScale={colors}
                              labels={({ datum }) => datum.y && `${datum.y}%`}
                              style={{
                                labels: {
                                  fill: 'white',
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </svg>
                          <Text fontWeight={500}> - 18 ans</Text>
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          width={280}
                          height={280}
                        >
                          <svg viewBox='0 0 400 375'>
                            <VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              data={item[filter[key]][`${key}In18-40`]}
                              innerRadius={70}
                              labelRadius={100}
                              colorScale={colors}
                              labels={({ datum }) => datum.y && `${datum.y}%`}
                              style={{
                                labels: {
                                  fill: 'white',
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </svg>
                          <Text fontWeight={500}>entre 18 et 40 ans</Text>
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          width={280}
                          height={280}
                        >
                          <svg viewBox='0 0 400 375'>
                            <VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              data={item[filter[key]][`${key}In41-60`]}
                              innerRadius={70}
                              labelRadius={100}
                              colorScale={colors}
                              labels={({ datum }) => datum.y && `${datum.y}%`}
                              style={{
                                labels: {
                                  fill: 'white',
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </svg>
                          <Text fontWeight={500}>entre 41 et 60 ans</Text>
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          width={280}
                          height={280}
                        >
                          <svg viewBox='0 0 400 375'>
                            <VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              data={item[filter[key]][`${key}Sup60`]}
                              innerRadius={70}
                              labelRadius={100}
                              colorScale={colors}
                              labels={({ datum }) => datum.y && `${datum.y}%`}
                              style={{
                                labels: {
                                  fill: 'white',
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </svg>
                          <Text fontWeight={500}>entre 61 et 70 ans</Text>
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          width={280}
                          height={280}
                        >
                          <svg viewBox='0 0 400 375'>
                            <VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              data={item[filter[key]][`${key}Sup70`]}
                              innerRadius={70}
                              labelRadius={100}
                              colorScale={colors}
                              labels={({ datum }) => datum.y && `${datum.y}%`}
                              style={{
                                labels: {
                                  fill: 'white',
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </svg>
                          <Text fontWeight={500}>entre 71 et 80 ans</Text>
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          alignItems='center'
                          width={280}
                          height={280}
                        >
                          <svg viewBox='0 0 400 375'>
                            <VictoryPie
                              standalone={false}
                              width={400}
                              height={400}
                              data={item[filter[key]][`${key}Sup80`]}
                              innerRadius={70}
                              labelRadius={100}
                              colorScale={colors}
                              labels={({ datum }) => datum.y && `${datum.y}%`}
                              style={{
                                labels: {
                                  fill: 'white',
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </svg>
                          <Text fontWeight={500}> + 80 ans</Text>
                        </Box>
                      </>
                    )}
                  </Box>
                  {renderLegend(key)}
                </Box>
              </Fragment>
            );
          })}
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => statisticsStyles()}
`;

export default Statistics;
