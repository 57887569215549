import React from 'react';

//Material
//Icons

//Styles
import styled from 'styled-components';
import { ic_description } from 'react-icons-kit/md/ic_description';
import { publicitesStyles } from './publicites.styles';
//Types
// import { AutomatedTextsFormProps } from './automatedTexts.types';
//Commons
import Text from '../../commons/Text';
//Components
import SideBar from '../../components/Drawer';

//Modules

//Assets/Images

//Actions

//Reducers

//Utils

const Publicites: React.FC<any> = (props) => {
  React.useEffect(() => {
    /* eslint-disable-next-line */
  }, []);

  return (
    <SideBar title='Publicités' icon={ic_description}>
      <Wrapper>
        <div className='card'>
          <Text fontSize={16} lineHeight={36}>
            Bonjour,
            <br />
            Ceci est une option. Vous pourrez diffuser de la publicité sur
            l'écran dédié à la liste d'attente de passage des personnes. Un
            bandeau en bas de l'écran indiquera le numéro appelé et l'espace au
            dessus sera dédié aux bannieres publicitaires. <br /> Pour plus de
            renseignement, veuillez contacter la société NBILITY à l'adresse :
            <br />
            <a href='mailto: contact@nbility.fr'>contact@nbility.fr</a>
          </Text>
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => publicitesStyles()}
`;

export default Publicites;
