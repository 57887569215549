import moment from 'moment';

import {
  DepartmentApiResponse,
  RegionApiResponse,
  UserApiResponse,
} from '../../actions/typesApi';
import { isEmpty, isEmail } from '../../utils/validations';
import { RegionDepartmentDetailState } from './regionDepartmentDetail.types';

export const regionDepartmentProfileGetRegionDepartmentObject = (
  regionDepartment: any
) => {
  let newRegionDepartment: any = { ...regionDepartment };

  newRegionDepartment = {
    _id: regionDepartment._id,
    name: regionDepartment.name,
    logo: regionDepartment.logo,
    headOffice: regionDepartment.headOffice,

    president: regionDepartment.president && {
      _id: regionDepartment.president._id,
      name: `${regionDepartment.president.firstName} ${regionDepartment.president.lastName}`,
    },

    vicePresident: regionDepartment.vicePresident && {
      _id: regionDepartment.vicePresident._id,
      name: `${regionDepartment.vicePresident.firstName} ${regionDepartment.vicePresident.lastName}`,
    },

    responsibles: regionDepartment.responsibles.map((responsible: any) => ({
      _id: responsible._id,
      name: `${responsible.firstName} ${responsible.lastName}`,
    })),
  };

  return newRegionDepartment;
};
