import axios from 'axios';
import { URL } from './configApi';
import {
  AUTOMATED_TEXTS_PROPS_CHANGED,
  AUTOMATED_TEXTS_GET_TEXTS_SUCCESS,
  AUTOMATED_TEXTS_GET_TEXTS_FAILED,
  AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS,
  AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED,
  AUTOMATED_TEXTS_INITIAL_STATE,
  AppThunk,
} from './types';

import {
  AutomatedTextsPropsChangedAction,
  AutomatedTextsInitialStateAction,
} from '../modules/AutomatedTexts/automatedTexts.types';

export const automatedTextsPropsChanged = (
  prop: string,
  value: any
): AutomatedTextsPropsChangedAction => ({
  type: AUTOMATED_TEXTS_PROPS_CHANGED,
  prop,
  value,
});

export const automatedTextsIntialState =
  (): AutomatedTextsInitialStateAction => ({
    type: AUTOMATED_TEXTS_INITIAL_STATE,
  });

export const automatedTextsGetTexts = (): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/admin/settings/manager`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: AUTOMATED_TEXTS_GET_TEXTS_SUCCESS,
          payload: data.response.data[0],
        });
      }
    })
    .catch((error) => {
      if (error.response?.status === 500) {
        dispatch({
          type: AUTOMATED_TEXTS_GET_TEXTS_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const automatedTextsUpdateTexts =
  (props: any, callback: () => void): AppThunk =>
  (dispatch) => {
    const data = {
      creatingIntervenant: props.creatingIntervenant,
      creatingCompaign: props.creatingCompaign,
    };

    axios
      .put(`${URL}/admin/settings/manager`, data)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          dispatch({
            type: AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS,
            payload: data.response.data,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED,
            payload: 'Error',
          });
        }
      });
  };
