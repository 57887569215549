import axios from 'axios';
import { URL } from './configApi';
import {
  RESET_PASSWORD_PROPS_CHANGED,
  RESET_PASSWORD_PROPS_VALIDATION,
  RESET_PASSWORD_VERIFICATION_TOKEN_FAILED,
  RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_INITIAL_STATE,
  AppThunk,
} from './types';
import { validateFieldsResetPassword } from '../modules/ResetPassword/resetPassword.utils';

import {
  ResetPasswordState,
  ResetPasswordPropsChangedAction,
  ResetPasswordInitialStateAction,
} from '../modules/ResetPassword/resetPassword.types';

export const resetPasswordPropsChanged = (
  prop: string,
  value: any
): ResetPasswordPropsChangedAction => ({
  type: RESET_PASSWORD_PROPS_CHANGED,
  prop,
  value,
});

export const resetPasswordverificationToken =
  (token: string): AppThunk =>
  (dispatch) => {
    axios
      .post(`${URL}/users//verification_token/${token}`)
      .then((response: any) => {
        if (response.data.status === 'success') {
          dispatch({
            type: RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS,
          });
        }
      })
      .catch((error: any) => {
        if (error.response.status === 400) {
          dispatch({
            type: RESET_PASSWORD_VERIFICATION_TOKEN_FAILED,
            payload:
              'Désolé, votre token a expiré ! Vous devrez renvoyer votre email.',
          });
        }
      });
  };

export const resetPasswordConfirm =
  (token: string, props: ResetPasswordState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsResetPassword(props);
    dispatch({ type: RESET_PASSWORD_PROPS_VALIDATION, payload: newProps });

    if (valid) {
      dispatch({
        type: RESET_PASSWORD_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });

      axios
        .patch(`${URL}/users/reset_password/${token}`, {
          password: props.password,
          passwordConfirm: props.passwordConfirm,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            setTimeout(() => {
              dispatch({
                type: RESET_PASSWORD_SUCCESS,
              });
              callback();
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            dispatch({
              type: RESET_PASSWORD_FAILED,
              payload:
                'Désolé, votre token a expiré ! Vous devrez renvoyer votre email.',
            });
          }
        });
    }
  };

export const resetPasswordIntialState =
  (): ResetPasswordInitialStateAction => ({
    type: RESET_PASSWORD_INITIAL_STATE,
  });
