import moment from 'moment';

import {
  DepartmentApiResponse,
  RegionApiResponse,
  UserApiResponse,
} from '../../actions/typesApi';
import { getItemFromLocalStorage } from '../../utils/storage';
import { isEmpty, isEmail } from '../../utils/validations';
import { CampagneProfileState } from './campagneProfile.types';

export const campagneProfileGetCampagneObject = (campagne: any) => {
  let newCampagne: any = { ...campagne };

  newCampagne = {
    _id: campagne._id,
    name: campagne.name,
    address: campagne.address,
    country: campagne.country,
    codePostal: campagne.codePostal,
    phoneNumber: campagne.phoneNumber,
    startDate: campagne.startDate,
    endDate: campagne.endDate,
    description: campagne.description,
    url: campagne.url,
    organizer: `${campagne.organizer.firstName} ${campagne.organizer.lastName}`,
    interveners:
      campagne.interveners &&
      campagne.interveners.map((intervener: any) => ({
        _id: intervener._id,
        name: `${intervener.firstName} ${intervener.lastName}`,
      })),
    department: campagne.department && {
      _id: campagne.department._id,
      name: campagne.department.name,
    },
    interventions:
      campagne.interventions &&
      campagne.interventions.map((intervention: any) => ({
        _id: intervention._id,
        name: intervention.name,
      })),
  };

  return newCampagne;
};

export const validateFieldsCampagneProfile = (props: CampagneProfileState) => {
  const newProps: CampagneProfileState = { ...props };

  if (isEmpty(newProps.name)) {
    newProps.nameError = 'Nom campagne est obligatoire';
  } else {
    newProps.nameError = '';
  }

  // if (!newProps.organizer) {
  //   newProps.organizerError = 'Organisateur est obligatoire';
  // } else {
  //   newProps.organizerError = '';
  // }

  if (isEmpty(newProps.phoneNumber)) {
    newProps.phoneNumberError = 'Numéro de téléphone est obligatoire';
  } else {
    newProps.phoneNumberError = '';
  }

  if (!newProps.department) {
    newProps.departmentError = 'Departement est obligatoire';
  } else {
    newProps.departmentError = '';
  }

  let valid = false;
  if (
    newProps.nameError === '' &&
    // newProps.organizerError === '' &&
    newProps.phoneNumberError === '' &&
    newProps.departmentError === ''
  ) {
    valid = true;
  }

  return { newProps, valid };
};

export const campagneProfileObject = (campagne: CampagneProfileState) => {
  const user = getItemFromLocalStorage('user');
  const interveners: any = [];
  const interventions: any = [];

  if (campagne.interveners.length !== 0) {
    campagne.interveners.forEach((intervener) => {
      interveners.push(intervener._id);
    });
  }
  if (campagne.interventions.length !== 0) {
    campagne.interventions.forEach((intervention) => {
      interventions.push(intervention._id);
    });
  }

  let newCampagne: any = { ...campagne };

  newCampagne = {
    name: campagne.name,
    address: campagne.address,
    country: campagne.country,
    codePostal: campagne.codePostal,
    phoneNumber: campagne.phoneNumber,
    startDate: campagne.startDate,
    endDate: campagne.endDate,
    description: campagne.description,
    url:
      campagne.department &&
      `https://francerein.app/${campagne.department.name}`,
    organizer: user._id,
    department: campagne.department && campagne.department._id,
    interveners,
    interventions,
  };

  return newCampagne;
};

export const campagneSummaryObject = (props: any) => {
  const user = getItemFromLocalStorage('user');

  const campagneSummary = {
    name: props.name,
    address: props.address,
    country: props.country,
    codePostal: props.codePostal,
    organizer: `${user.firstName} ${user.lastName}`,
    phoneNumber: props.phoneNumber,
    department: props.department,
    interveners: props.interveners,
    startDate: props.startDate,
    endDate: props.endDate,
    interventions: props.interventions,
    description: props.description,
  };

  return campagneSummary;
};
