import 'date-fns';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';

//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
//Styles
import styled from 'styled-components';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { campagneProfileStyles, useStyles } from './campagneProfile.styles';
//Types
import { CampagneProfileFormProps } from './campagneProfile.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
import TextArea from '../../commons/Inputs/InputTextArea';

//Components
import Tags from '../../components/Tags';
import Modal from '../../components/Modal';

//Modules
import AddIntervention from '../AddIntervention';
import AddIntervener from '../AddIntervener';
import CampagneSummary from './CampagneSummary';
//Assets/Images
import camera from '../../assets/images/camera.png';
//Actions
import {
  getAllList,
  campagneProfilePropsChanged,
  campagneProfileGetCampagne,
  campagneProfileCreateCampagne,
  campagneProfileConfirmCreateCampagne,
  campagneProfileUpdateCampagne,
  campagneProfileIntialState,
} from '../../actions/campagneProfileActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
import { validateFieldsCampagneProfile } from './campagneProfile.utils';

//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const CampagneProfile: React.FC<any> = (props) => {
  const [openAddInterventionType, setOpenAddInterventionType] =
    React.useState<boolean>(false);
  const [openAddIntervener, setOpenAddIntervener] =
    React.useState<boolean>(false);
  const [openCreateCampagne, setOpenCreateCampagne] =
    React.useState<boolean>(false);

  const classes = useStyles();
  const {
    organizersList,
    departmentsList,
    interventionsList,
    intervenersList,
    campagneSummary,
    name,
    address,
    country,
    codePostal,
    // organizer,
    phoneNumber,
    department,
    interveners,
    startDate,
    endDate,
    interventions,
    description,
    // url,
    nameError,
    // organizerError,
    phoneNumberError,
    departmentError,
    getCampagneMessageError,
    messageError,
    title,
    textButton,
    loading,
    loadingConfirm,
    loadingPage,
  } = props;

  const { campagneId } = props.match.params;
  const user = getItemFromLocalStorage('user');

  const disabledField = !!campagneId;

  React.useEffect(() => {
    props.campagneProfileIntialState();
    props.getAllList(user._id);
    if (campagneId) {
      props.campagneProfileGetCampagne(campagneId);
    }
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.campagneProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleDateChange = (prop: string) => (date: Moment | null) => {
    props.campagneProfilePropsChanged(prop, date);
  };

  const handleClickSave = () => {
    if (campagneId) {
      props.campagneProfileUpdateCampagne(campagneId, props, () => {
        props.snackbarShowMessage('La campagne est modifiée avec succès');
        props.history.push('/campagnes');
      });
    } else {
      props.campagneProfileCreateCampagne(props, () => {
        setOpenCreateCampagne(!openCreateCampagne);
      });
    }
  };

  const handleConfirmCreateCampagne = () => {
    props.campagneProfileConfirmCreateCampagne(campagneSummary, () => {
      props.snackbarShowMessage('La campagne est ajoutée avec succès');
      props.history.push('/campagnes');
    });
  };

  const handleClickCancel = () => {
    props.history.goBack();
  };

  if (campagneId && loadingPage) {
    return (
      <Wrapper>
        <div className='card'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  }
  if (!loadingPage && getCampagneMessageError) {
    return (
      <Wrapper>
        <div className='card'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {getCampagneMessageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        <Text
          fontWeight={600}
          fontSize={16}
          lineHeight={24}
          style={{ width: '1000px' }}
        >
          {title}
        </Text>
        <div className='card'>
          <Modal
            open={openCreateCampagne}
            onClose={() => setOpenCreateCampagne(!openCreateCampagne)}
          >
            <CampagneSummary
              campagneSummary={campagneSummary}
              onClickConfirm={handleConfirmCreateCampagne}
              onClickCancel={() => setOpenCreateCampagne(!openCreateCampagne)}
              loading={loadingConfirm}

              // onSaveChanged={() =>
              //   setOpenAddInterventionType(!openAddInterventionType)
              // }
            />
          </Modal>
          <Modal
            open={openAddInterventionType}
            onClose={() => setOpenAddInterventionType(!openAddInterventionType)}
          >
            <AddIntervention
              onSaveChanged={() =>
                setOpenAddInterventionType(!openAddInterventionType)
              }
            />
          </Modal>
          <Modal
            open={openAddIntervener}
            onClose={() => setOpenAddIntervener(!openAddIntervener)}
          >
            <AddIntervener
              onSaveChanged={() => setOpenAddIntervener(!openAddIntervener)}
            />
          </Modal>
          <div className='main-content'>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    value={name}
                    type='text'
                    name='name'
                    placeholder='Nom Intervention'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                  {nameError && <Text error>{nameError}</Text>}
                </Grid>

                <Grid item xs={6}>
                  <Input
                    value={address}
                    type='text'
                    name='address'
                    placeholder='Adresse'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    value={country}
                    type='text'
                    name='country'
                    placeholder='Ville'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    value={codePostal}
                    type='text'
                    name='codePostal'
                    placeholder='Code postal'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    disabled
                    value={`${user.firstName} ${user.lastName}`}
                    type='text'
                    name='organizer'
                    placeholder='Organisateur'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                  {/* <Tags
                    multiple={false}
                    options={organizersList}
                    tags={organizer}
                    placeholder='Organisateur'
                    getTags={(organizer) => {
                      props.campagneProfilePropsChanged('organizer', organizer);
                    }}
                    hideIconAdd
                  ></Tags>
                  {organizerError && <Text error>{organizerError}</Text>} */}
                </Grid>

                <Grid item xs={6}>
                  <Input
                    value={phoneNumber}
                    type='text'
                    name='phoneNumber'
                    placeholder='Numéro de téléphone'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                  {phoneNumberError && <Text error>{phoneNumberError}</Text>}
                </Grid>
                <Grid item xs={12}>
                  <Tags
                    multiple={false}
                    options={departmentsList}
                    tags={department}
                    placeholder='Département'
                    getTags={(department) => {
                      props.campagneProfilePropsChanged(
                        'department',
                        department
                      );
                    }}
                    hideIconAdd
                  />
                  {departmentError && <Text error>{departmentError}</Text>}
                </Grid>

                <Grid item xs={12}>
                  <Tags
                    multiple
                    options={intervenersList}
                    tags={interveners}
                    placeholder='Intervenant'
                    getTags={(interveners) => {
                      props.campagneProfilePropsChanged(
                        'interveners',
                        interveners
                      );
                    }}
                    handleClickAdd={() => {
                      setOpenAddIntervener(!openAddIntervener);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    required
                    margin='normal'
                    id='start-date'
                    label='Date Début'
                    format='dd/MM/yyyy'
                    value={startDate}
                    onChange={handleDateChange('startDate')}
                    // error={startDateError !== ''}
                    // helperText={getFormatedMessage('startDateError')}
                    // disablePast
                    inputVariant='outlined'
                    // KeyboardButtonProps={{
                    //   'aria-label': 'change date',
                    // }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    required
                    margin='normal'
                    id='end-date'
                    label='Date Fin'
                    format='dd/MM/yyyy'
                    inputVariant='outlined'
                    value={endDate}
                    onChange={handleDateChange('endDate')}
                    // error={endDateError !== ''}
                    // helperText={getFormatedMessage('endDateError')}
                    // disablePast
                    minDate={startDate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Tags
                    multiple
                    options={interventionsList}
                    tags={interventions}
                    placeholder='Type intervention'
                    getTags={(interventions) => {
                      props.campagneProfilePropsChanged(
                        'interventions',
                        interventions
                      );
                    }}
                    handleClickAdd={() => {
                      setOpenAddInterventionType(!openAddInterventionType);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextArea
                    value={description}
                    type='text'
                    name='description'
                    placeholder='Description'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Input
                    value={url}
                    type='text'
                    name='url'
                    placeholder='https://'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                </Grid> */}

                <Grid
                  item
                  xs={6}
                  container
                  alignItems='center'
                  justify='center'
                >
                  {messageError && <Text error>{messageError}</Text>}
                </Grid>
              </Grid>
            </div>
            <div className='main-footer'>
              <Button onClick={handleClickCancel} width={180} margin={10}>
                Annuler
              </Button>
              <Button onClick={handleClickSave} width={180} margin={10}>
                {loading ? (
                  <CircularProgress size={20} color='inherit' />
                ) : (
                  textButton
                )}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

const Wrapper = styled('div')`
  ${() => campagneProfileStyles()}
`;

const mapStateToProps = ({ campagneProfile }: RootState) => ({
  organizersList: campagneProfile.organizersList,
  departmentsList: campagneProfile.departmentsList,
  interventionsList: campagneProfile.interventionsList,
  intervenersList: campagneProfile.intervenersList,
  campagneSummary: campagneProfile.campagneSummary,

  name: campagneProfile.name,
  address: campagneProfile.address,
  country: campagneProfile.country,
  codePostal: campagneProfile.codePostal,
  // organizer: campagneProfile.organizer,
  phoneNumber: campagneProfile.phoneNumber,
  department: campagneProfile.department,
  interveners: campagneProfile.interveners,
  startDate: campagneProfile.startDate,
  endDate: campagneProfile.endDate,
  interventions: campagneProfile.interventions,
  description: campagneProfile.description,
  // url: campagneProfile.url,
  nameError: campagneProfile.nameError,
  // organizerError: campagneProfile.organizerError,
  phoneNumberError: campagneProfile.phoneNumberError,
  departmentError: campagneProfile.departmentError,
  getCampagneMessageError: campagneProfile.getCampagneMessageError,
  messageError: campagneProfile.messageError,
  title: campagneProfile.title,
  textButton: campagneProfile.textButton,
  loading: campagneProfile.loading,
  loadingConfirm: campagneProfile.loadingConfirm,
  loadingPage: campagneProfile.loadingPage,
});

export const connector = connect(mapStateToProps, {
  getAllList,
  campagneProfilePropsChanged,
  campagneProfileGetCampagne,
  campagneProfileCreateCampagne,
  campagneProfileConfirmCreateCampagne,
  campagneProfileUpdateCampagne,
  campagneProfileIntialState,
});

export default connector(withSnackbar(CampagneProfile));
