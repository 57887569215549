import { css } from 'styled-components';

export const loginStyles = (props: any) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  .card {
    display: flex;
    height: 640px;
    width: 1000px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
    border-radius: 20px;
    .card-left-col {
      /* width: 385px; */
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
    .card-right-col {
      flex: 2;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .div-inputs {
        display: flex;
        flex-direction: column;
        margin: 25px;
        .forgot-password-txt {
          align-self: flex-end;
          margin-top: 5px;
          cursor: pointer;
        }
      }
    }
    .divider {
      border-left: 0.5px solid rgba(192, 192, 192, 0.69);
      margin: 75px 0;
    }
  }
`;
