import {
  CAMPAGNE_DETAIL_GET_CAMPAGNE_SUCCESS,
  CAMPAGNE_DETAIL_GET_CAMPAGNE_FAILED,
  CAMPAGNE_DETAIL_INITIAL_STATE,
} from '../actions/types';
import {
  CampagneDetailState,
  CampagneDetailActionTypes,
} from '../modules/CampagneDetail/campagneDetail.types';

const INITIAL_STATE: CampagneDetailState = {
  name: '',
  address: '',
  country: '',
  codePostal: '',
  organizer: '',
  phoneNumber: '',
  interveners: [],
  department: null,
  startDate: '',
  endDate: '',
  interventions: [],
  description: '',
  url: '',
  messageError: '',
  loadingPage: true,
};

export default (
  state = INITIAL_STATE,
  action: CampagneDetailActionTypes
): CampagneDetailState => {
  switch (action.type) {
    case CAMPAGNE_DETAIL_GET_CAMPAGNE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingPage: false,
      };

    case CAMPAGNE_DETAIL_GET_CAMPAGNE_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loadingPage: false,
      };

    case CAMPAGNE_DETAIL_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
