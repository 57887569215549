import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

// Material
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from 'react-icons-kit';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { wallet, cog } from 'react-icons-kit/entypo';
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard';
import { ic_assignment } from 'react-icons-kit/md/ic_assignment';
import { ic_people } from 'react-icons-kit/md/ic_people';
import { ic_settings_input_svideo } from 'react-icons-kit/md/ic_settings_input_svideo';
import { ic_videocam } from 'react-icons-kit/md/ic_videocam';
import { ic_description } from 'react-icons-kit/md/ic_description';

import StatisticsIcon from '@material-ui/icons/EqualizerOutlined';
// Styles
import { useStyles } from './drawer.styles';
// Assets/Images
import logo from '../../assets/images/logo.png';
import user from '../../assets/images/user.jpg';
//Components
import Modal from '../Modal';
// Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
// Actions
import { URL_IMAGE } from '../../actions/configApi';

// Utils
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from '../../utils/storage';

const MenuList = [
  {
    text: 'Dashboard',
    path: 'statistics',
    icon: <Icon icon={ic_dashboard} size='20' style={{ color: '#000' }} />,
  },
  {
    text: 'Infos générales',
    path: 'general-infos',
    icon: <Icon icon={ic_assignment} size='20' style={{ color: '#000' }} />,
  },
  {
    text: 'Intervenants',
    path: 'interveners',
    icon: <Icon icon={ic_people} size='20' style={{ color: '#000' }} />,
  },
  {
    text: 'Campagnes',
    path: 'campagnes',
    icon: (
      <Icon
        icon={ic_settings_input_svideo}
        size='20'
        style={{ color: '#000' }}
      />
    ),
  },
  {
    text: 'Textes automatisés',
    path: 'automated-texts',
    icon: <Icon icon={ic_description} size='20' style={{ color: '#000' }} />,
  },
  {
    text: 'Vidéo conférence',
    path: 'video-conference',
    icon: <Icon icon={ic_videocam} size='20' style={{ color: '#000' }} />,
  },
  {
    text: 'Publicités',
    path: 'publicites',
    icon: <Icon icon={ic_description} size='20' style={{ color: '#000' }} />,
  },
];

interface DrawerProps extends RouteComponentProps {
  title?: string;
  icon?: string;
  // handleClick?: () => void;
  // handleClose?: () => void;
  // handleLogout?: () => void;
  // onClickMenu?: (path: string) => void;
  children?: React.ReactNode;
}

const SideBar: React.FC<DrawerProps> = (props) => {
  const currentPath = window.location.pathname;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { title, icon } = props;
  const user = getItemFromLocalStorage('user');

  const [openModalContact, setOpenModalContact] =
    React.useState<boolean>(false);

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeItemFromLocalStorage('token');
    removeItemFromLocalStorage('user');
    props.history.push('/login');
  };

  const onClickMenu = (path: string) => {
    props.history.push(`/${path}`);
  };

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        {icon && (
          <Toolbar>
            <Icon
              icon={icon}
              size='24'
              style={{
                color: '#373737',
                marginRight: '8px',
                marginBottom: '4px',
              }}
            />
            <Text fontSize={16} fontWeight={700} lineHeight={1} color='#373737'>
              {title}
            </Text>
          </Toolbar>
        )}
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <img className={classes.logoSymbole} src={logo} alt='logo' />
          </div>
        </div>
        <hr className={classes.divider} />
        <div className={classes.profileContainer}>
          <div className={classes.imageContent}>
            <div
              role='button'
              tabIndex={0}
              className={classes.polygonContent}
              onClick={handleClickProfile}
            >
              <div className={classes.polygon} />
            </div>
            {user.photo ? (
              <Avatar
                alt='Avatar'
                src={`${URL_IMAGE}/${user.photo}`}
                // src={user}
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.avatar}>
                {`${user.firstName.charAt(0).toUpperCase()}${user.lastName
                  .charAt(0)
                  .toUpperCase()}`}
              </Avatar>
            )}
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseProfile}
              //  className={classes.menu}
            >
              <MenuItem
                onClick={() => {
                  props.history.push('/update-profile');
                }}
              >
                Profil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.history.push('/update-password');
                }}
              >
                Mot de passe
              </MenuItem>
            </Menu>
          </div>

          <Button
            height={30}
            margin={10}
            // width={120}
            background='#FF7E04'
            onClick={() => {
              console.log('admin');
            }}
          >
            {`${user.firstName} ${user.lastName}`}
          </Button>
        </div>

        <List>
          {MenuList.map((item, index) => {
            const selected = item.path
              ? currentPath.includes(item.path)
              : false;

            return (
              <ListItem
                key={item.text}
                selected={selected}
                button
                onClick={() => onClickMenu(item.path)}
                // style={{
                //   height: '57px',
                //   background:
                //     'linear-gradient(60deg,rgb(72 20 244) -30%,rgb(247 90 160) 50%,rgb(254 174 110) 130%)',
                // }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={item.text}
                />
              </ListItem>
            );
          })}
        </List>
        <div className={classes.footer}>
          <Modal
            open={openModalContact}
            onClose={() => setOpenModalContact(!openModalContact)}
          >
            <Text fontSize={16} lineHeight={36}>
              Pour contacter le support, merci de nous envoyer un mail à
              l'adresse suivante : <br />
              <a href='mailto: support@nbility.fr'>support@nbility.fr</a>
            </Text>
          </Modal>
          <Button
            height={35}
            width={180}
            background='#FF7E04'
            onClick={() => {
              setOpenModalContact(!openModalContact);
            }}
          >
            CONTACT SUPPORT
          </Button>
          <Button height={35} width={180} onClick={handleLogout}>
            DECONNEXION
          </Button>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

export default withRouter(SideBar);
