import axios from 'axios';
import { URL } from './configApi';
import {
  INTERVENERS_PROPS_CHANGED,
  INTERVENERS_GET_LIST_SUCCESS,
  INTERVENERS_GET_LIST_FAILED,
  INTERVENERS_GET_LIST_FILTER_INTERVENERS,
  INTERVENERS_DELETE_INTERVENER_SUCCESS,
  INTERVENERS_DELETE_INTERVENER_FAILED,
  INTERVENERS_INITIAL_STATE,
  AppThunk,
} from './types';

import { GetUsersApiResponse, UserApiResponse } from './typesApi';
import {
  IntervenersPropsChangedAction,
  IntervenersInitialStateAction,
} from '../modules/Interveners/interveners.types';

export const intervenersPropsChanged = (
  prop: string,
  value: any
): IntervenersPropsChangedAction => ({
  type: INTERVENERS_PROPS_CHANGED,
  prop,
  value,
});

export const intervenersGetListInterveners =
  (filter: string): AppThunk =>
  (dispatch) => {
    let urlWithPermission =
      'users?permission=volunteer&permission=professional';
    if (filter === 'volunteer') {
      urlWithPermission = 'users?permission=volunteer';
    } else if (filter === 'professional') {
      urlWithPermission = 'users?permission=professional';
    }

    axios
      // .get(`${URL}/users?permission[ne]=admin&page=${page}&limit=${limit}`)
      .get(`${URL}/${urlWithPermission}`)
      .then(({ data }: { data: GetUsersApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: INTERVENERS_GET_LIST_SUCCESS,
            payload: data.response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: INTERVENERS_GET_LIST_FAILED,
            payload: 'Network Error',
          });
        }
      });
  };

export const intervenersGetFilterInterveners =
  (search: string, interveners: Array<UserApiResponse>): AppThunk =>
  (dispatch) => {
    const intervenersFilter: Array<UserApiResponse> = Object.assign(
      [],
      interveners
    );

    dispatch({
      type: INTERVENERS_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    const result = intervenersFilter.filter(
      (item: UserApiResponse) =>
        item.firstName.toLocaleLowerCase().match(search.toLocaleLowerCase()) ||
        item.lastName.toLocaleLowerCase().match(search.toLocaleLowerCase())
    );

    dispatch({
      type: INTERVENERS_GET_LIST_FILTER_INTERVENERS,
      search,
      payload: result,
    });
  };

export const intervenersDeleteIntervener =
  (
    intervenersId: string,
    interveners: Array<UserApiResponse>,
    callback: () => void
  ): AppThunk =>
  (dispatch) => {
    dispatch({
      type: INTERVENERS_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .delete(`${URL}/users/${intervenersId}`)
      .then((response) => {
        const newUsersList: Array<UserApiResponse> = Object.assign(
          [],
          interveners
        );
        if (response.status === 204) {
          dispatch({
            type: INTERVENERS_DELETE_INTERVENER_SUCCESS,
            payload: newUsersList.filter(
              (user: UserApiResponse) => user._id !== intervenersId
            ),
          });
        }
        callback();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: INTERVENERS_DELETE_INTERVENER_FAILED,
            payload: 'Aucun utilisateur trouvé avec cet identifiant.',
          });
        }
      });
  };

export const intervenersInitialState = (): IntervenersInitialStateAction => ({
  type: INTERVENERS_INITIAL_STATE,
});
