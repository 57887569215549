import {
  INTERVENER_PROFILE_GET_INTERVENER_SUCCESS,
  INTERVENER_PROFILE_GET_INTERVENER_FAILED,
  INTERVENER_PROFILE_PROPS_CHANGED,
  INTERVENER_PROFILE_PROPS_VALIDATION,
  INTERVENER_PROFILE_CREATE_INTERVENER_SUCCESS,
  INTERVENER_PROFILE_CREATE_INTERVENER_FAILED,
  INTERVENER_PROFILE_UPDATE_INTERVENER_SUCCESS,
  INTERVENER_PROFILE_UPDATE_INTERVENER_FAILED,
  INTERVENER_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  IntervenerProfileState,
  IntervenerProfileActionTypes,
} from '../modules/IntervenerProfile/intervenerProfile.types';

const INITIAL_STATE: IntervenerProfileState = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  country: '',
  codePostal: '',
  phoneNumber: '',
  mobile: '',
  photo: null,
  permission: 'volunteer',
  accessBackOffice: true,

  firstNameError: '',
  lastNameError: '',
  emailError: '',
  permissionError: '',
  messageError: '',
  getIntervenerMessageError: '',
  title: "Création d'un intervenant",
  textButton: 'Ajouter Intervenant',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: IntervenerProfileActionTypes
): IntervenerProfileState => {
  switch (action.type) {
    case INTERVENER_PROFILE_GET_INTERVENER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        title: 'Modifier Intervenant',
        textButton: 'Modifier Intervenant',
        loadingPage: false,
      };

    case INTERVENER_PROFILE_GET_INTERVENER_FAILED:
      return {
        ...state,
        getIntervenerMessageError: action.payload,
        loadingPage: false,
      };

    case INTERVENER_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case INTERVENER_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case INTERVENER_PROFILE_CREATE_INTERVENER_SUCCESS:
      return { ...state, loading: false };

    case INTERVENER_PROFILE_CREATE_INTERVENER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case INTERVENER_PROFILE_UPDATE_INTERVENER_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case INTERVENER_PROFILE_UPDATE_INTERVENER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case INTERVENER_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
