import React from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';

//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';

//Style
import styled from 'styled-components';
import { campagneDetailStyles, useStyles } from './campagneDetail.styles';
//Types
import { campagneDetailFormProps } from './campagneDetail.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
import TextArea from '../../commons/Inputs/InputTextArea';

//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';

//Actions
import {
  campagneDetailGetCampagne,
  campagneDetailInitialState,
} from '../../actions/campagneDetailActions';
//Reducers
import { RootState } from '../../reducers';

//Utils

const CampagneDetail: React.FC<campagneDetailFormProps> = (props) => {
  const [copy, setCopy] = React.useState(false);
  const classes = useStyles();
  const {
    name,
    address,
    country,
    codePostal,
    organizer,
    phoneNumber,
    department,
    interveners,
    startDate,
    endDate,
    interventions,
    description,
    url,
    messageError,
    loadingPage,
    campagneId,
  } = props;

  React.useEffect(() => {
    //  props.addUserInitialState();
    props.campagneDetailGetCampagne(campagneId);
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // props.campagneProfilePropsChanged(event.target.name, event.target.value);
  };

  if (campagneId && loadingPage) {
    return (
      <Wrapper>
        <div className='main-content'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  }
  if (!loadingPage && messageError) {
    return (
      <Wrapper>
        <div className='main-content'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {messageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ alignSelf: 'flex-start', marginBottom: '20px' }}
      >
        Détail Campagne
      </Text>

      <div className='main-content'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              disabled
              value={name}
              type='text'
              name='name'
              placeholder='Nom Intervention'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              disabled
              value={address}
              type='text'
              name='address'
              placeholder='Adresse'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              disabled
              value={country}
              type='text'
              name='country'
              placeholder='Ville'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              disabled
              value={codePostal}
              type='text'
              name='codePostal'
              placeholder='Code postal'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            {/* <Tags
              disabled
              multiple={false}
              options={[]}
              tags={organizer}
              placeholder='Organisateur'
              getTags={(organizer) => {}}
              hideIconAdd
            ></Tags> */}
            <Input
              disabled
              value={organizer}
              type='text'
              name='organizer'
              placeholder='Organisateur'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              disabled
              value={phoneNumber}
              type='text'
              name='phoneNumber'
              placeholder='Numéro de téléphone'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Tags
              disabled
              multiple={false}
              options={[]}
              tags={department}
              placeholder='Département'
              getTags={(region) => {}}
              hideIconAdd
            />
          </Grid>

          <Grid item xs={12}>
            <Tags
              disabled
              multiple
              options={[]}
              tags={interveners}
              placeholder='Intervenant'
              getTags={(interveners) => {}}
              hideIconAdd
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              disabled
              value={moment(startDate).format('DD/MM/YYYY')}
              type='text'
              name='startDate'
              placeholder='Date début'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              disabled
              value={moment(endDate).format('DD/MM/YYYY')}
              type='text'
              name='endDate'
              placeholder='Date fin'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Tags
              disabled
              multiple
              options={[]}
              tags={interventions}
              placeholder='Type intervention'
              getTags={(interventions) => {}}
              hideIconAdd
            />
          </Grid>

          <Grid item xs={12}>
            <TextArea
              disabled
              value={description}
              type='text'
              name='description'
              placeholder='Description'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div className='div-link-input'>
              <Input
                disabled
                value={url}
                type='text'
                name='url'
                style={{ background: '#FBFAFA' }}
                placeholder=''
                margin='10px 0px'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEventChange(e)
                }
              />
              <CopyToClipboard
                text={url}
                onCopy={() => {
                  setCopy(true);
                }}
              >
                <Icon
                  icon={ic_content_copy}
                  size='20'
                  style={{
                    color: '#373737',
                    marginLeft: '10px',
                    cursor: 'pointer',
                  }}
                />
              </CopyToClipboard>
              {/* {copy ? (
                <span style={{ margin: '0 8px', color: '#373737' }}>copie</span>
              ) : null} */}
            </div>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => campagneDetailStyles()}
`;

const mapStateToProps = ({ campagneDetail }: RootState) => ({
  name: campagneDetail.name,
  address: campagneDetail.address,
  country: campagneDetail.country,
  codePostal: campagneDetail.codePostal,
  organizer: campagneDetail.organizer,
  phoneNumber: campagneDetail.phoneNumber,
  department: campagneDetail.department,
  interveners: campagneDetail.interveners,
  startDate: campagneDetail.startDate,
  endDate: campagneDetail.endDate,
  interventions: campagneDetail.interventions,
  description: campagneDetail.description,
  url: campagneDetail.url,
  messageError: campagneDetail.messageError,
  loadingPage: campagneDetail.loadingPage,
});

export const connector = connect(mapStateToProps, {
  campagneDetailGetCampagne,
  campagneDetailInitialState,
});

export default connector(React.memo(CampagneDetail));
