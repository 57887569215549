function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// group state object by all | male | female and convert it to { x: number ,y: number } format
export function groupByKey(obj: { [key: string]: any }, keyword: string) {
  return (
    obj &&
    Object.keys(obj)
      .filter((key) => key.includes(keyword))
      .reduce((result: any, key) => {
        let gender = '';
        if (key.includes('All')) {
          gender = 'all';
        } else if (key.includes('Male')) {
          gender = 'male';
        } else if (key.includes('Female')) {
          gender = 'female';
        }

        const currentValue = obj[key];
        const newKey = key.replace(capitalize(gender), '');
        return {
          ...result,
          [gender]: {
            ...(result[gender] || {}),
            [newKey]: currentValue.map((item: any) => ({
              x: item[keyword],
              y: item.total ? Math.round(item.percentage) : null,
            })),
          },
        };
      }, {})
  );
}
