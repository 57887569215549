import { isEmpty, isEmail } from '../../utils/validations';
// import { AddUserState } from '../AddUser/addUser.types';
import { IntervenerProfileState } from './intervenerProfile.types';
import { AddIntervenerState } from '../AddIntervener/addIntervener.types';

export const validateFieldsIntervenerProfile = (
  props: IntervenerProfileState | AddIntervenerState
) => {
  const newProps: IntervenerProfileState | AddIntervenerState = {
    ...props,
  };

  if (isEmpty(newProps.firstName)) {
    newProps.firstNameError = 'Nom est obligatoire';
  } else {
    newProps.firstNameError = '';
  }

  if (isEmpty(newProps.lastName)) {
    newProps.lastNameError = 'Prénom est obligatoire';
  } else {
    newProps.lastNameError = '';
  }

  if (isEmpty(newProps.email)) {
    newProps.emailError = 'Email est obligatoire';
  } else if (!isEmail(newProps.email)) {
    newProps.emailError = 'Email invalide';
  } else {
    newProps.emailError = '';
  }

  if (isEmpty(newProps.permission)) {
    newProps.permissionError = 'Permission est obligatoire';
  } else {
    newProps.permissionError = '';
  }

  let valid = false;
  if (
    newProps.firstNameError === '' &&
    newProps.lastNameError === '' &&
    newProps.emailError === '' &&
    newProps.permissionError === ''
  ) {
    valid = true;
  }

  return { newProps, valid };
};

export const intervenerProfileCreateIntervenerObject = (
  intervener: IntervenerProfileState | AddIntervenerState
) => {
  const formData = new FormData();

  if (
    intervener.firstName &&
    intervener.lastName &&
    intervener.email &&
    intervener.permission
  ) {
    formData.append('firstName', intervener.firstName);
    formData.append('lastName', intervener.lastName);
    formData.append('email', intervener.email);
    formData.append('address', intervener.address);
    formData.append('country', intervener.country);
    formData.append('codePostal', intervener.codePostal);
    formData.append('phoneNumber', intervener.phoneNumber);
    formData.append('mobile', intervener.mobile);
    formData.append('permission', intervener.permission);
    // formData.append('accessBackOffice', new Boolean(intervener.accessBackOffice).toString());
    intervener.photo && formData.append('photo', intervener.photo);
    return formData;
  }
  return null;
};

export const intervenerProfileUpdateIntervenerObject = (
  intervener: IntervenerProfileState | AddIntervenerState
) => {
  const formData = new FormData();

  if (intervener.firstName && intervener.lastName && intervener.email) {
    formData.append('firstName', intervener.firstName);
    formData.append('lastName', intervener.lastName);
    formData.append('address', intervener.address);
    formData.append('country', intervener.country);
    formData.append('codePostal', intervener.codePostal);
    formData.append('phoneNumber', intervener.phoneNumber);
    formData.append('mobile', intervener.mobile);
    // formData.append('accessBackOffice', new Boolean(intervener.accessBackOffice).toString());
    intervener.photo && formData.append('photo', intervener.photo);
    return formData;
  }
  return null;
};
