import axios from 'axios';
import { URL } from './configApi';
import {
  GENERAL_INFOS_PROPS_CHANGED,
  GENERAL_INFOS_GET_LIST_SUCCESS,
  GENERAL_INFOS_GET_LIST_FAILED,
  GENERAL_INFOS_GET_LIST_FILTER_INFOS,
  GENERAL_INFOS_INITIAL_STATE,
  AppThunk,
} from './types';

import { GetUsersApiResponse, UserApiResponse } from './typesApi';
import {
  GeneralInfosPropsChangedAction,
  GeneralInfosInitialStateAction,
} from '../modules/GeneralInfos/genralInfos.types';

export const generalInfosPropsChanged = (
  prop: string,
  value: any
): GeneralInfosPropsChangedAction => ({
  type: GENERAL_INFOS_PROPS_CHANGED,
  prop,
  value,
});

export const generalInfosGetInfos =
  (userId: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${URL}/region_department/general_infos/${userId}`)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          dispatch({
            type: GENERAL_INFOS_GET_LIST_SUCCESS,
            payload: data.response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          dispatch({
            type: GENERAL_INFOS_GET_LIST_FAILED,
            payload: 'Network Error',
          });
        }
      });
  };

export const generalInfosGetFilterInfos =
  (search: string, infos: Array<any>): AppThunk =>
  (dispatch) => {
    const infosFilter: Array<UserApiResponse> = Object.assign([], infos);

    dispatch({
      type: GENERAL_INFOS_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    const result = infosFilter.filter((item: any) =>
      item.name.toLocaleLowerCase().match(search.toLocaleLowerCase())
    );

    dispatch({
      type: GENERAL_INFOS_GET_LIST_FILTER_INFOS,
      search,
      payload: result,
    });
  };

export const generalInfosInitialState = (): GeneralInfosInitialStateAction => ({
  type: GENERAL_INFOS_INITIAL_STATE,
});
