import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
//Styles
//Material
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
//Commons
import Button from '../../../commons/Button';
//Actions
import { URL_IMAGE } from '../../../actions/configApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: 'none',
    },
    container: {
      maxHeight: '100%',
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F6F9FD',
      },
    },
  })
)(TableRow);

interface StickyHeadTableProps extends RouteComponentProps {
  data?: Array<any>;
  count: number;
  page: number;
  rowsPerPage: number;
  changePage: (event: unknown, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clickDelete: (value: string) => void;
}

type PermissionType = 'volunteer' | 'professional';

const StickyHeadTable: React.FC<StickyHeadTableProps> = (props) => {
  const classes = useStyles();

  const {
    data,
    count,
    rowsPerPage,
    page,
    changePage,
    changeRowsPerPage,
    clickDelete,
  } = props;

  const permissionTranslate = (permission: PermissionType) => {
    switch (permission) {
      case 'volunteer':
        return 'Bénévole';
      case 'professional':
        return 'Professionnel';
      default:
        return '';
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>Photo</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Permission</TableCell>
              <TableCell align='center'>Actions</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row._id}
                  >
                    <TableCell key={row._id}>
                      {row.photo ? (
                        <Avatar
                          alt='Avatar'
                          src={`${URL_IMAGE}/${row.photo}`}
                          className={classes.avatar}
                        />
                      ) : (
                        <Avatar className={classes.avatar}>{`${row.firstName
                          .charAt(0)
                          .toUpperCase()}${row.lastName
                          .charAt(0)
                          .toUpperCase()}`}</Avatar>
                      )}
                    </TableCell>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{permissionTranslate(row.permission)}</TableCell>
                    {/* <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      > */}
                    <TableCell>
                      <Button
                        onClick={() =>
                          props.history.push(`/interveners/${row._id}`)
                        }
                        width={90}
                        background='#FFA550'
                      >
                        Modifier
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => clickDelete(row._id)}
                        width={100}
                        background='#FF67C6'
                      >
                        Supprimer
                      </Button>
                    </TableCell>
                    {/* </div> */}
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage='Lignes par page'
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </Paper>
  );
};

export default withRouter(StickyHeadTable);
