import {
  UPDATE_PASSWORD_PROPS_CHANGED,
  UPDATE_PASSWORD_PROPS_VALIDATION,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_INITIAL_STATE,
} from '../actions/types';
import {
  UpdatePasswordState,
  UpdatePasswordActionTypes,
} from '../modules/UpdatePassword/updatePassword.types';

const INITIAL_STATE: UpdatePasswordState = {
  passwordCurrent: '',
  newPassword: '',
  passwordConfirm: '',
  passwordCurrentError: '',
  newPasswordError: '',
  passwordConfirmError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: UpdatePasswordActionTypes
): UpdatePasswordState => {
  switch (action.type) {
    case UPDATE_PASSWORD_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case UPDATE_PASSWORD_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_PASSWORD_FAILED:
      return { ...state, passwordCurrentError: action.payload, loading: false };

    case UPDATE_PASSWORD_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
