import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
// Material

// Styles

import styled from 'styled-components';
import { generalInfosStyles } from './generalInfos.styles';
// Types
import { GeneralInfosListProps } from './genralInfos.types';
// Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
// Components
import EmptyData from '../../components/EmptyData';
import Table from '../../components/Tables/GeneralInfosTable';
import Modal from '../../components/Modal';
// Modules

// Assets/Images
import emptyState from '../../assets/images/emptyState.svg';
// Actions
import {
  generalInfosGetInfos,
  generalInfosPropsChanged,
  generalInfosGetFilterInfos,
  generalInfosInitialState,
} from '../../actions/generalInfosActions';
// Reducers
import { RootState } from '../../reducers';
// Utils
import { getItemFromLocalStorage } from '../../utils/storage';
import RegionDepartmentDetail from '../RegionDepartmentDetail';

const GeneralInfos: React.FC<GeneralInfosListProps> = (props) => {
  const { infos, infosFilter, search, messageError, loading } = props;
  const [page, setPage] = React.useState(0);
  const [limit, setlimitPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [regionDepartmentId, setRegionDepartmentId] = React.useState('');
  const [regionDepartmentType, setRegionDepartmentType] = React.useState('');
  const user = getItemFromLocalStorage('user');

  const [openGeneralInfosDetail, setOpenGeneralInfosDetail] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    props.generalInfosGetInfos(user._id);
    /* eslint-disable-next-line */
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setlimitPage(+event.target.value);
    setPage(0);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.generalInfosGetFilterInfos(event.target.value, infosFilter);
    props.generalInfosPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSearch = () => {
    props.generalInfosGetFilterInfos(search, infosFilter);
  };

  return (
    <Wrapper>
      <Modal
        open={openGeneralInfosDetail}
        onClose={() => setOpenGeneralInfosDetail(!openGeneralInfosDetail)}
      >
        <RegionDepartmentDetail
          regionDepartmentId={regionDepartmentId}
          regionDepartmentType={regionDepartmentType}
        />
      </Modal>
      <div className='main-header'>
        <div className='div-input-search'>
          <Input
            value={search}
            type='text'
            name='search'
            placeholder='Rechercher'
            width={1000}
            margin='10px 0'
            className='input-search'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEventChange(e)
            }
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#373737'
            className='button-search'
          >
            Rechercher
          </Button>
        </div>
      </div>

      <div className='card'>
        <EmptyData
          data={infos}
          loading={loading}
          message='La liste des régions est vide'
          messageError={messageError}
          image={emptyState}
        >
          <Table
            data={infos}
            clickDetail={(regionDepartmentId, regionDepartmentType) => {
              setOpenGeneralInfosDetail(!openGeneralInfosDetail);
              setRegionDepartmentId(regionDepartmentId);
              setRegionDepartmentType(regionDepartmentType);
            }}
            count={infos.length}
            rowsPerPage={limit}
            page={page}
            changePage={handleChangePage}
            changeRowsPerPage={handleChangeRowsPerPage}
          />
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => generalInfosStyles()}
`;

const mapStateToProps = ({ generalInfos }: RootState) => ({
  infos: generalInfos.infos,
  infosFilter: generalInfos.infosFilter,
  search: generalInfos.search,
  messageError: generalInfos.messageError,
  loading: generalInfos.loading,
});

export const connector = connect(mapStateToProps, {
  generalInfosGetInfos,
  generalInfosPropsChanged,
  generalInfosGetFilterInfos,
  generalInfosInitialState,
});

export default connector(GeneralInfos);
