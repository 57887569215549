import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Avatar from '@material-ui/core/Avatar';
// Commons
import Tree from '../../../commons/Tree';

import Button from '../../../commons/Button';
import Text from '../../../commons/Text';
// Actions
import { URL_IMAGE } from '../../../actions/configApi';
//Images
import regionLogo from '../../../assets/images/region.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: 'none',
    },
    container: {
      maxHeight: '100%',
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   width: '100%',
    //   boxShadow: 'none',
    // },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);

interface CollapsibleTableProps extends RouteComponentProps {
  data: Array<any>;
  count: number;
  page: number;
  rowsPerPage: number;
  changePage: (event: unknown, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clickDetail: (id: string, type: string) => void;
}

const CollapsibleTable: React.FC<CollapsibleTableProps> = (props) => {
  const classes = useStyles();
  const {
    data,
    count,
    rowsPerPage,
    page,
    changePage,
    changeRowsPerPage,
    clickDetail,
  } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell>LOGO</TableCell>
              <TableCell>NOM</TableCell>
              <TableCell>RESPONSABLES</TableCell>
              {/* <TableCell>PRÉSIDENT</TableCell>
              <TableCell>VICE PRÉSIDENT</TableCell> */}
              <TableCell>Actions</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell colSpan={7}>
                  <Tree
                    key={row._id}
                    last={row && row.length === index + 1}
                    img={row.logo ? `${URL_IMAGE}/${row.logo}` : '#'}
                    type='region'
                    data={[
                      row.name || '',
                      row.responsibles.map(
                        (responsible: any, index: number) =>
                          // return `${index !== 0 && ', '} ${
                          //   responsible.firstName
                          // } ${responsible.lastName}`;
                          `${responsible.firstName} ${responsible.lastName} `
                      ),
                      // row.president
                      //   ? `${row.president.firstName} ${row.president.lastName}`
                      //   : '--------- --------',
                      // row.vicePresident
                      //   ? `${row.vicePresident.firstName} ${row.vicePresident.lastName}`
                      //   : '----------- ----------',
                    ]}
                    onClickDetail={() => clickDetail(row._id, 'region')}
                  >
                    {row.departments &&
                      row.departments.map((department: any, index: number) => (
                        <Tree
                          key={department._id}
                          type='department'
                          last={
                            row.departments &&
                            row.departments.length === index + 1
                          }
                          data={[
                            department.name || '',
                            department.responsibles.map(
                              (responsible: any, index: number) =>
                                // return `${index !== 0 && ', '} ${
                                //   responsible.firstName
                                // } ${responsible.lastName}`;
                                `${responsible.firstName} ${responsible.lastName} `
                            ),
                            // row.president
                            //   ? `${row.president.firstName} ${row.president.lastName}`
                            //   : '----------- ----------',
                            // row.vicePresident
                            //   ? `${row.vicePresident.firstName} ${row.vicePresident.lastName}`
                            //   : '----------- ----------',
                          ]}
                          onClickDetail={() =>
                            clickDetail(department._id, 'department')
                          }
                        />
                      ))}
                  </Tree>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage='Lignes par page'
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </Paper>
  );
};

export default withRouter(CollapsibleTable);
