import axios from 'axios';
import { URL } from './configApi';
import {
  ADD_INTERVENTION_PROPS_CHANGED,
  ADD_INTERVENTION_FAILED,
  ADD_INTERVENTION_INITIAL_STATE,
  CAMPAGNE_PROFILE_ADD_INTERVENTION,
  AppThunk,
} from './types';
import {
  AddInterventionPropsChangedAction,
  AddInterventionInitialStateAction,
} from '../modules/AddIntervention/addIntervention.types';

export const addInterventionPropsChanged = (
  prop: string,
  value: any
): AddInterventionPropsChangedAction => ({
  type: ADD_INTERVENTION_PROPS_CHANGED,
  prop,
  value,
});

export const addInterventionConfirm =
  (intervention: string, callback: () => void): AppThunk =>
  (dispatch) => {
    dispatch({
      type: ADD_INTERVENTION_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .post(`${URL}/interventions`, { name: intervention })
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          dispatch({
            type: CAMPAGNE_PROFILE_ADD_INTERVENTION,
            payload: {
              _id: data.response.data._id,
              name: data.response.data.name,
            },
          });
          dispatch({
            type: ADD_INTERVENTION_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: ADD_INTERVENTION_FAILED,
            payload: 'Intervention existe déjà.',
          });
        }
      });
  };

export const addInterventionInitialState =
  (): AddInterventionInitialStateAction => ({
    type: ADD_INTERVENTION_INITIAL_STATE,
  });
