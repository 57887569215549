import React from 'react';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress, FormControlLabel } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

//Style
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

import { addIntervenerStyles } from './addIntervener.styles';
//Types
import { AddIntervenerFormProps } from './addIntervener.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';

//Actions
import {
  addIntervenerPropsChanged,
  addIntervenerConfirm,
  addIntervenerInitialState,
} from '../../actions/addIntervenerActions';
//Reducers
import { RootState } from '../../reducers';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const permissions = [
  {
    id: 'volunteer',
    label: 'Bénévole',
  },
  {
    id: 'professional',
    label: 'Professionnel',
  },
];

const CustomCheckbox = withStyles({
  root: {
    color: '#DD0089',
    '&$checked': {
      color: '#DD0089',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

const AddIntervener: React.FC<AddIntervenerFormProps> = (props) => {
  const {
    firstName,
    lastName,
    email,
    address,
    country,
    codePostal,
    phoneNumber,
    mobile,
    photo,
    permission,
    accessBackOffice,
    firstNameError,
    lastNameError,
    emailError,
    permissionError,
    messageError,
    loading,
    onSaveChanged,
    snackbarShowMessage,
  } = props;

  React.useEffect(() => {
    props.addIntervenerInitialState();
    /* eslint-disable-next-line */
  }, []);

  // const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   props.addIntervenerPropsChanged('accessBackOffice', event.target.checked);
  // };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.addIntervenerPropsChanged('permission', event.target.value);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.addIntervenerPropsChanged('photo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addIntervenerPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    props.addIntervenerConfirm(props, () => {
      snackbarShowMessage('Utilisateur est ajouté avec succès');
      setTimeout(() => {
        onSaveChanged();
      }, 2000);
    });
  };

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ alignSelf: 'flex-start' }}
      >
        Ajouter Intervenant
      </Text>
      <div className='main-header'>
        <UploadImage
          id='intervenerImage'
          image={photo}
          emptyImage={null}
          onChange={(e) => {
            handleFileChange(e);
          }}
        />
      </div>
      <div className='main-content'>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Input
              value={firstName}
              type='text'
              name='firstName'
              placeholder='Nom'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {firstNameError && <Text error>{firstNameError}</Text>}
          </Grid>
          <Grid item xs={6}>
            <Input
              value={lastName}
              type='text'
              name='lastName'
              placeholder='Prénom'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {lastNameError && <Text error>{lastNameError}</Text>}
          </Grid>
          <Grid item xs={12}>
            <Input
              value={email}
              type='text'
              name='email'
              placeholder='Email'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {emailError && <Text error>{emailError}</Text>}
          </Grid>
          <Grid item xs={6}>
            <Input
              value={address}
              type='text'
              name='address'
              placeholder='Adresse'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              value={country}
              type='text'
              name='country'
              placeholder='Ville'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              value={codePostal}
              type='text'
              name='codePostal'
              placeholder='Code postal'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              value={phoneNumber}
              type='text'
              name='phoneNumber'
              placeholder='Numéro de téléphone'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              value={mobile}
              type='text'
              name='mobile'
              placeholder='Mobile'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: '10px' }}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='permission'>Permission</InputLabel>
                <Select
                  value={permission}
                  name='permission'
                  onChange={handleSelectChange}
                  label='Permission'
                  id='permission'
                  // inputProps={{ className: 'text-field-select' }}
                >
                  {permissions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Text color='#373737'>{option.label}</Text>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {permissionError && <Text error>{permissionError}</Text>}
          </Grid>
          {/* <Grid item xs={6}>
            <div style={{ marginTop: '15px', marginLeft: '10px' }}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={accessBackOffice}
                    onChange={handleCheckBoxChange}
                    name='accessBackOffice'
                  />
                }
                label='Accéder au backoffice'
              />
            </div>
          </Grid> */}
          <Grid item xs={6} container alignItems='center' justify='center'>
            {messageError && <Text error>{messageError}</Text>}
          </Grid>
        </Grid>

        <div className='main-footer'>
          <Button onClick={handleClickSave} width={180} margin={10}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Ajouter'
            )}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => addIntervenerStyles()}
`;

const mapStateToProps = ({ addIntervener }: RootState) => ({
  firstName: addIntervener.firstName,
  lastName: addIntervener.lastName,
  email: addIntervener.email,
  address: addIntervener.address,
  country: addIntervener.country,
  codePostal: addIntervener.codePostal,
  phoneNumber: addIntervener.phoneNumber,
  mobile: addIntervener.mobile,
  photo: addIntervener.photo,
  permission: addIntervener.permission,
  accessBackOffice: addIntervener.accessBackOffice,
  firstNameError: addIntervener.firstNameError,
  lastNameError: addIntervener.lastNameError,
  emailError: addIntervener.emailError,
  permissionError: addIntervener.permissionError,
  messageError: addIntervener.messageError,
  loading: addIntervener.loading,
});

export const connector = connect(mapStateToProps, {
  addIntervenerPropsChanged,
  addIntervenerConfirm,
  addIntervenerInitialState,
});

export default connector(withSnackbar(AddIntervener));
