import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import ForgotPasswordReducer from './forgotPasswordReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import UpdatePasswordReducer from './updatePasswordReducer';
import ManagerProfileReducer from './managerProfileReducer';
import GeneralInfosReducer from './generalInfosReducer';
import IntervenersReducer from './intervenersReducer';
import IntervenerProfileReducer from './intervenerProfileReducer';
import CampagneProfileReducer from './campagneProfileReducer';
import AddIntervenerReducer from './addIntervenerReducer';
import AddInterventionReducer from './addInterventionReducer';
import CampagnesReducer from './campagnesReducer';
import CampagneDetailReducer from './campagneDetailReducer';
import RegionDepartmentDetailReducer from './regionDepartmentDetailReducer';
import AutomatedTextsReducer from './automatedTextsReducer';
import staticReducer from './statisticReducer';

const reducers = combineReducers({
  login: LoginReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  updatePassword: UpdatePasswordReducer,
  managerProfile: ManagerProfileReducer,
  generalInfos: GeneralInfosReducer,
  interveners: IntervenersReducer,
  intervenerProfile: IntervenerProfileReducer,
  campagneProfile: CampagneProfileReducer,
  addIntervener: AddIntervenerReducer,
  addIntervention: AddInterventionReducer,
  campagnes: CampagnesReducer,
  campagneDetail: CampagneDetailReducer,
  regionDepartmentDetail: RegionDepartmentDetailReducer,
  automatedTexts: AutomatedTextsReducer,
  statistics: staticReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
