import {
  CAMPAGNES_GET_LIST_INTERVENTIONS_SUCCESS,
  CAMPAGNES_GET_LIST_INTERVENTIONS_FAILED,
  CAMPAGNES_PROPS_CHANGED,
  CAMPAGNES_GET_LIST_SUCCESS,
  CAMPAGNES_GET_LIST_FAILED,
  CAMPAGNES_GET_LIST_FILTER_CAMPAGNES,
  CAMPAGNE_PROFILE_ADD_INTERVENTION,
  CAMPAGNES_DELETE_CAMPAGNE_SUCCESS,
  CAMPAGNES_DELETE_CAMPAGNE_FAILED,
  CAMPAGNES_DELETE_INTERVENTION_SUCCESS,
  CAMPAGNES_DELETE_INTERVENTION_FAILED,
  CAMPAGNES_INITIAL_STATE,
} from '../actions/types';
import {
  CampagnesState,
  CampagnesActionTypes,
} from '../modules/Campagnes/campagnes.types';

const INITIAL_STATE: CampagnesState = {
  campagnes: [],
  campagnesFilter: [],
  interventions: [],
  events: [],
  search: '',
  messageError: '',
  loading: true,
};

export default (
  state = INITIAL_STATE,
  action: CampagnesActionTypes
): CampagnesState => {
  switch (action.type) {
    case CAMPAGNES_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case CAMPAGNES_GET_LIST_INTERVENTIONS_SUCCESS:
      return {
        ...state,
        interventions: action.payload,
        loading: false,
      };

    case CAMPAGNES_GET_LIST_INTERVENTIONS_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case CAMPAGNES_GET_LIST_SUCCESS:
      return {
        ...state,
        campagnes: action.payload,
        campagnesFilter: action.payload,
        events: action.events,
        loading: false,
      };

    case CAMPAGNES_GET_LIST_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case CAMPAGNES_GET_LIST_FILTER_CAMPAGNES: {
      return {
        ...state,
        search: action.search,
        campagnes: action.payload,
        loading: false,
      };
    }

    case CAMPAGNES_DELETE_CAMPAGNE_SUCCESS:
      return { ...state, campagnes: action.payload, loading: false };

    case CAMPAGNES_DELETE_CAMPAGNE_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case CAMPAGNES_DELETE_INTERVENTION_SUCCESS:
      return { ...state, interventions: action.payload, loading: false };

    case CAMPAGNES_DELETE_INTERVENTION_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case CAMPAGNE_PROFILE_ADD_INTERVENTION:
      return {
        ...state,
        interventions: [...[action.payload], ...state.interventions],
      };

    case CAMPAGNES_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
