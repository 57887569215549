import React from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';

//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar, CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';

//Style
import styled from 'styled-components';
import {
  regionDeparmenDetailStyles,
  useStyles,
} from './regionDepartmentDetail.styles';
//Types
import { regionDepartmentDetailFormProps } from './regionDepartmentDetail.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
import TextArea from '../../commons/Inputs/InputTextArea';

//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';

//Actions
import {
  regionDepartmentDetailGetRegionDepartment,
  regionDepartmentDetailInitialState,
} from '../../actions/regionDepartmentDetailActions';
//Reducers
import { RootState } from '../../reducers';
import { URL_IMAGE } from '../../actions/configApi';

//Utils

const RegionDepartmentDetail: React.FC<regionDepartmentDetailFormProps> = (
  props
) => {
  const classes = useStyles();
  const {
    name,
    logo,
    headOffice,
    president,
    vicePresident,
    responsibles,
    messageError,
    loadingPage,
    regionDepartmentId,
    regionDepartmentType,
  } = props;

  React.useEffect(() => {
    // props.regionDepartmentDetailInitialState();
    props.regionDepartmentDetailGetRegionDepartment(
      regionDepartmentId,
      regionDepartmentType
    );
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // props.campagneProfilePropsChanged(event.target.name, event.target.value);
  };

  if (regionDepartmentId && loadingPage) {
    return (
      <Wrapper>
        <div className='main-content'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  }
  if (!loadingPage && messageError) {
    return (
      <Wrapper>
        <div className='main-content'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {messageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ alignSelf: 'flex-start', marginBottom: '20px' }}
      >
        Détail
      </Text>
      <div className='main-header'>
        <Avatar
          alt='Avatar'
          src={`${URL_IMAGE}/${logo}`}
          // src={user}
          className='avatar'
        />
      </div>
      <div className='main-content'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              disabled
              value={name}
              type='text'
              name='name'
              placeholder='Nom'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              disabled
              value={headOffice}
              type='text'
              name='headOffice'
              placeholder='Siége Social'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>

          <Grid item xs={6}>
            <Tags
              disabled
              multiple={false}
              options={[]}
              tags={president}
              placeholder='Président'
              getTags={(president) => {}}
              hideIconAdd
            />
          </Grid>
          <Grid item xs={6}>
            <Tags
              disabled
              multiple={false}
              options={[]}
              tags={vicePresident}
              placeholder='Vice Président'
              getTags={(vicePresident) => {}}
              hideIconAdd
            />
          </Grid>

          <Grid item xs={12}>
            <Tags
              disabled
              multiple
              options={[]}
              tags={responsibles}
              placeholder='Responsables'
              getTags={(responsibles) => {}}
              hideIconAdd
            />
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => regionDeparmenDetailStyles()}
`;

const mapStateToProps = ({ regionDepartmentDetail }: RootState) => ({
  name: regionDepartmentDetail.name,
  headOffice: regionDepartmentDetail.headOffice,
  logo: regionDepartmentDetail.logo,
  president: regionDepartmentDetail.president,
  vicePresident: regionDepartmentDetail.vicePresident,
  responsibles: regionDepartmentDetail.responsibles,
  messageError: regionDepartmentDetail.messageError,
  loadingPage: regionDepartmentDetail.loadingPage,
});

export const connector = connect(mapStateToProps, {
  regionDepartmentDetailGetRegionDepartment,
  regionDepartmentDetailInitialState,
});

export default connector(React.memo(RegionDepartmentDetail));
