import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
// Styles
import { ic_supervisor_account } from 'react-icons-kit/md/ic_supervisor_account';
import { useStyles } from './generalInfos.styles';
// Icons
// Components
import SideBar from '../../components/Drawer';
// Modules
import GeneralInfos from '../../modules/GeneralInfos';
import NotFound from '../../modules/NotFound';

const GeneralInfosRouter: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title='Infos Générals' icon={ic_supervisor_account}>
        <Switch>
          <Route path='/general-infos' component={GeneralInfos} exact />
          <Route path='/general-infos/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default GeneralInfosRouter;
