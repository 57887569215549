import React from 'react';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress, FormControlLabel } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

//Style
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

import { addInterventionStyles } from './addIntervention.styles';
//Types
import { AddInterventionFormProps } from './addIntervention.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';

//Actions
import {
  addInterventionPropsChanged,
  addInterventionConfirm,
  addInterventionInitialState,
} from '../../actions/addInterventionActions';
//Reducers
import { RootState } from '../../reducers';
import { isEmpty } from '../../utils/validations';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const AddIntervention: React.FC<AddInterventionFormProps> = (props) => {
  const {
    intervention,
    messageError,
    loading,
    onSaveChanged,
    snackbarShowMessage,
  } = props;

  React.useEffect(() => {
    props.addInterventionInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addInterventionPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    if (isEmpty(intervention)) {
      props.addInterventionPropsChanged(
        'messageError',
        "Veuillez fournir un nom d'intervention!"
      );
    } else {
      props.addInterventionPropsChanged('messageError', '');
      props.addInterventionConfirm(intervention, () => {
        snackbarShowMessage('Intervention est ajouté avec succès');
        setTimeout(() => {
          onSaveChanged();
        }, 1500);
      });
    }
  };

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ alignSelf: 'flex-start', marginBottom: '10px' }}
      >
        Type Intervenant
      </Text>
      <Input
        value={intervention}
        type='text'
        name='intervention'
        placeholder='Saisir intervention'
        margin='10px 0'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleEventChange(e)
        }
      />
      {messageError && <Text error>{messageError}</Text>}
      <Button
        onClick={handleClickSave}
        width={180}
        margin={10}
        style={{ alignSelf: 'flex-end' }}
      >
        {loading ? <CircularProgress size={20} color='inherit' /> : 'Ajouter'}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => addInterventionStyles()}
`;

const mapStateToProps = ({ addIntervention }: RootState) => ({
  intervention: addIntervention.intervention,
  messageError: addIntervention.messageError,
  loading: addIntervention.loading,
});

export const connector = connect(mapStateToProps, {
  addInterventionPropsChanged,
  addInterventionConfirm,
  addInterventionInitialState,
});
export default connector(withSnackbar(AddIntervention));
