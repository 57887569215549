import {
  STATISTICS_GET_COMPAIGNS_SUCCEED,
  STATISTICS_GET_COMPAIGNS_LOADING,
  STATISTICS_GET_COMPAIGNS_FAILED,
  STATISTICS_GET_STATS_FAILED,
  STATISTICS_GET_STATS_LOADING,
  STATISTICS_GET_STATS_SUCCEED,
} from '../actions/types';

export interface StatisticsGetRegionsFailed {
  type: typeof STATISTICS_GET_COMPAIGNS_FAILED;
}
export interface StatisticsGetRegionsLoading {
  type: typeof STATISTICS_GET_COMPAIGNS_LOADING;
}
export interface StatisticsGetRegionsSucceed {
  type: typeof STATISTICS_GET_COMPAIGNS_SUCCEED;
  payload: { [key: string]: any[] };
}

export interface StatisticsGetStatsFailed {
  type: typeof STATISTICS_GET_STATS_FAILED;
}
export interface StatisticsGetStatsLoading {
  type: typeof STATISTICS_GET_STATS_LOADING;
}
export interface StatisticsGetStatsSucceed {
  type: typeof STATISTICS_GET_STATS_SUCCEED;
  payload: { [key: string]: any[] };
}

export type StatisticsGetRegionsActionTypes =
  | StatisticsGetRegionsFailed
  | StatisticsGetRegionsLoading
  | StatisticsGetRegionsSucceed
  | StatisticsGetStatsFailed
  | StatisticsGetStatsLoading
  | StatisticsGetStatsSucceed;

const INITIAL_STATE = {
  departments: {},
  stats: {},
  loading: false,
};

type State = {
  departments: { [key: string]: any[] };
  stats: { [key: string]: any };
  loading: boolean;
};

export default (
  state = INITIAL_STATE,
  action: StatisticsGetRegionsActionTypes
): State => {
  switch (action.type) {
    case STATISTICS_GET_COMPAIGNS_SUCCEED:
      return {
        ...state,
        departments: { ...state.departments, ...action.payload },
      };

    case STATISTICS_GET_COMPAIGNS_LOADING:
      return { ...state, loading: false };

    case STATISTICS_GET_COMPAIGNS_FAILED:
      return { ...state, loading: false };

    case STATISTICS_GET_STATS_SUCCEED:
      return {
        ...state,
        stats: { ...state.stats, ...action.payload },
      };

    case STATISTICS_GET_STATS_LOADING:
      return { ...state, loading: false };

    case STATISTICS_GET_STATS_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};
