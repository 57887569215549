import moment from 'moment';
import {
  CAMPAGNE_GET_All_LIST_SUCCESS,
  CAMPAGNE_GET_All_LIST_FAILED,
  CAMPAGNE_PROFILE_GET_CAMPAGNE_SUCCESS,
  CAMPAGNE_PROFILE_GET_CAMPAGNE_FAILED,
  CAMPAGNE_PROFILE_PROPS_CHANGED,
  CAMPAGNE_PROFILE_PROPS_VALIDATION,
  CAMPAGNE_PROFILE_GET_CAMPAGNE_SUMMARY,
  CAMPAGNE_PROFILE_CREATE_CAMPAGNE_SUCCESS,
  CAMPAGNE_PROFILE_CREATE_CAMPAGNE_FAILED,
  CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_SUCCESS,
  CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_FAILED,
  CAMPAGNE_PROFILE_ADD_INTERVENER,
  CAMPAGNE_PROFILE_ADD_INTERVENTION,
  CAMPAGNE_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  CampagneProfileState,
  CampagneProfileActionTypes,
} from '../modules/CampagneProfile/campagneProfile.types';

const INITIAL_STATE: CampagneProfileState = {
  organizersList: [],
  intervenersList: [],
  interventionsList: [],
  departmentsList: [],
  campagneSummary: null,

  name: '',
  address: '',
  country: '',
  codePostal: '',
  // organizer: null,
  phoneNumber: '',
  interveners: [],
  department: null,
  startDate: moment(),
  endDate: moment(),
  interventions: [],
  description: '',
  // url:'',
  nameError: '',
  // organizerError: '',
  phoneNumberError: '',
  departmentError: '',
  messageError: '',
  getCampagneMessageError: '',
  title: 'Crée Campagne',
  textButton: 'Ajouter Campagne',
  loadingPage: true,
  loading: false,
  loadingConfirm: false,
};

export default (
  state = INITIAL_STATE,
  action: CampagneProfileActionTypes
): CampagneProfileState => {
  switch (action.type) {
    case CAMPAGNE_GET_All_LIST_SUCCESS:
      return {
        ...state,
        organizersList: action.allOrganizersList,
        intervenersList: action.allIntervenersList,
        interventionsList: action.allInterventionsList,
        departmentsList: action.allDepartmentsList,
      };

    case CAMPAGNE_GET_All_LIST_FAILED:
      return { ...state, messageError: action.payload };

    case CAMPAGNE_PROFILE_GET_CAMPAGNE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        title: 'Modifier Campagne',
        textButton: 'Modifier Campagne',
        loadingPage: false,
      };

    case CAMPAGNE_PROFILE_GET_CAMPAGNE_FAILED:
      return {
        ...state,
        getCampagneMessageError: action.payload,
        loadingPage: false,
      };

    case CAMPAGNE_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case CAMPAGNE_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };
    case CAMPAGNE_PROFILE_GET_CAMPAGNE_SUMMARY:
      return { ...state, campagneSummary: action.payload, loading: false };

    case CAMPAGNE_PROFILE_CREATE_CAMPAGNE_SUCCESS:
      return { ...state, loadingConfirm: false };

    case CAMPAGNE_PROFILE_CREATE_CAMPAGNE_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loadingConfirm: false,
      };

    case CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case CAMPAGNE_PROFILE_UPDATE_CAMPAGNE_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case CAMPAGNE_PROFILE_ADD_INTERVENER:
      return {
        ...state,
        intervenersList: [...[action.payload], ...state.intervenersList],
      };

    case CAMPAGNE_PROFILE_ADD_INTERVENTION:
      return {
        ...state,
        interventionsList: [...[action.payload], ...state.interventionsList],
      };

    case CAMPAGNE_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
