import { css } from 'styled-components';

export const publicitesStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .card {
    height: 200px;
    width: 1000px;
    margin: 20px;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
    border-radius: 20px;
  }
`;
