import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_control_point } from 'react-icons-kit/md/ic_control_point';
//Styles
import styled from 'styled-components';
import { ic_message } from 'react-icons-kit/md/ic_message';
import { automatedTextsStyles, useStyles } from './automatedTexts.styles';
//Types
import { AutomatedTextsFormProps } from './automatedTexts.types';
//Commons
import Text from '../../commons/Text';
import TextArea from '../../commons/Inputs/InputTextArea';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';
import Modal from '../../components/Modal';
import SideBar from '../../components/Drawer';

//Modules

//Assets/Images
import camera from '../../assets/images/camera.png';

//Actions
import {
  automatedTextsPropsChanged,
  automatedTextsGetTexts,
  automatedTextsUpdateTexts,
  automatedTextsIntialState,
} from '../../actions/automatedTextsActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
//hoc
import { withSnackbar } from '../../hoc/snackBarHoc';

const AutomatedTexts: React.FC<any> = (props) => {
  const { creatingIntervenant, creatingCompaign, loading, messageError } =
    props;

  React.useEffect(() => {
    props.automatedTextsGetTexts();

    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.automatedTextsPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    props.automatedTextsUpdateTexts(props, () => {
      props.snackbarShowMessage('Mise à jour avec succès');
    });
  };

  return (
    <SideBar title='Textes Automatisés' icon={ic_message}>
      <Wrapper>
        <>
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={24}
            style={{ width: '1000px' }}
          >
            Création compte intervenant (Bénévole/Professionnel)
          </Text>
          <div className='card'>
            <TextArea
              height={250}
              lineHeight={26}
              value={creatingIntervenant}
              type='text'
              name='creatingIntervenant'
              placeholder='Création Intervenant'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            <Button
              onClick={handleClickSave}
              width={140}
              margin={10}
              style={{ alignSelf: 'flex-end' }}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Mise à jour'
              )}
            </Button>
          </div>
        </>

        <>
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={24}
            style={{ width: '1000px' }}
          >
            {/* Intervention sur une campagne */}
            Création une campagne
          </Text>
          <div className='card'>
            <TextArea
              // height={250}
              lineHeight={26}
              value={creatingCompaign}
              type='text'
              name='creatingCompaign'
              placeholder='Création Campagne'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            <Button
              onClick={handleClickSave}
              width={140}
              margin={10}
              style={{ alignSelf: 'flex-end' }}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Mise à jour'
              )}
            </Button>
          </div>
        </>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => automatedTextsStyles()}
`;

const mapStateToProps = ({ automatedTexts }: RootState) => ({
  creatingIntervenant: automatedTexts.creatingIntervenant,
  creatingCompaign: automatedTexts.creatingCompaign,
  loading: automatedTexts.loading,
  messageError: automatedTexts.messageError,
});

export const connector = connect(mapStateToProps, {
  automatedTextsPropsChanged,
  automatedTextsGetTexts,
  automatedTextsUpdateTexts,
  automatedTextsIntialState,
});

export default connector(withSnackbar(AutomatedTexts));
