import React, { FC, Fragment } from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// STYLES
const styles = StyleSheet.create({
  wrapper: {
    padding: 20,
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },

  view: {
    margin: '10px 10px 0 10px',
  },

  title: {
    fontSize: '14',
    color: '#000',
    marginBottom: '5px',
  },

  subTitle: {
    fontSize: '14',
    color: '#757575',
    paddingBottom: '5px',
  },
});

type StatisticsPDFProps = {
  Region: string;
  Department: string;
  Companion: string;
  countDocument: number;
  gender: any;
  age: any;
  statsGrouped: any;
};
type Key = 'protein' | 'glucose' | 'blood';
type KeyWords = Record<Key, string>;

const handleGender = (gender: string) => {
  if (gender === 'female') return 'Femmme';
  return 'Homme';
};

const keyWords: KeyWords = {
  blood: 'Sang',
  glucose: 'Glucose',
  protein: 'Protéine',
};

const StatisticsPDF: FC<StatisticsPDFProps> = ({
  Region,
  Department,
  Companion,
  countDocument,
  gender,
  age,
  statsGrouped,
}) => {
  const returnAge = (age: string | null) => {
    switch (age) {
      case '≤18':
        return '<= 18 ans';
      case '18-40':
        return '18 - 40 ans';
      case '41-60':
        return '41 - 60 ans';
      case '>60':
        return '> 60 ans';
      case '>70':
        return '> 70 ans';
      case '>80':
        return '> 80 ans';
      default:
        return null;
    }
  };

  const returnProteinName = (name: string | null) => {
    switch (name) {
      case 'NEGATIVE_OR_TRACES':
        return 'Négatif ou Traces';
      case '≥0,3G/L':
        return '>= 0,3 g/l';
      case '≥1G/L':
        return '>= 1 g/l';
      case '≥3G/L':
        return '>= 3 g/l';
      default:
        return null;
    }
  };

  const returnBloodName = (name: string | null) => {
    switch (name) {
      case 'NEGATIVE_OR_TRACES':
        return 'Négatif ou Traces';
      case '1_CROIX':
        return '>= 1 croix (25 g/mm³)';
      case '2_CROIX':
        return '>= 2 croix (80 g/mm³)';
      case '3_CROIX':
        return '>= 3 croix (80 g/mm³)';
      case 'ANTICOAGULANT_TREATMENT':
        return 'Traitement anticoagulant';
      default:
        return null;
    }
  };

  const returnGlucoseName = (name: string | null) => {
    switch (name) {
      case 'NEGATIVE_OR_TRACES':
        return 'Négatif ou Traces';
      case '1_CROIX':
        return '>= 1 croix (2,5 g/l) ou (14 mmol/l)';
      case '2_CROIX':
        return '>= 2 croix (5 g/l) ou (28 mmol/l)';
      case '3_CROIX':
        return '>= 3 croix (10 g/l) ou (55 mmol/l)';
      default:
        return null;
    }
  };

  const handleName = (name: string | null, kind: Key) => {
    switch (kind) {
      case 'protein':
        return returnProteinName(name);
      case 'blood':
        return returnBloodName(name);
      case 'glucose':
        return returnGlucoseName(name);
      default:
        return null;
    }
  };

  return (
    <Document>
      <Page size='A4' style={styles.wrapper}>
        <View style={styles.view}>
          <Text style={styles.title}>
            Région : <Text style={styles.subTitle}>{Region}</Text>, Département
            : <Text style={styles.subTitle}>{Department}</Text>, Compagne :{' '}
            <Text style={styles.subTitle}>{Companion}</Text>.
          </Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.title}>
            Nombres des fiches enregistrés : {countDocument || 0}{' '}
          </Text>
          {gender?.map((item: any, index: number) => (
            <Text style={styles.subTitle} key={index}>
              {handleGender(item.gender)} : {Math.round(item.percentage)}%
            </Text>
          ))}
        </View>
        {age && (
          <View style={styles.view}>
            <View style={styles.header}>
              <Text style={styles.title}>Tranche d'age</Text>
            </View>
            {age.map((item: any, index: number) => (
              <Text style={styles.subTitle} key={index}>
                {returnAge(item?.age)} : {Math.round(item?.percentage)}%
              </Text>
            ))}
          </View>
        )}
        {statsGrouped &&
          Object.entries(statsGrouped)?.map(([k, item]: [string, any]) => {
            const key = k as Key;
            return (
              <Fragment key={key}>
                <View style={styles.view}>
                  <View style={styles.header}>
                    <Text style={styles.title}>{keyWords[key]}</Text>
                  </View>
                  <Text style={styles.title}>Tous : </Text>
                  {item.all?.[key]?.map((item: any, index: number) => (
                    <Text style={styles.subTitle} key={index}>
                      {handleName(item.x, key)} : {Math.round(item?.y)}%
                    </Text>
                  ))}
                  <Text style={styles.title}>Homme : </Text>
                  {item.all?.[`${key}Male`]?.map((item: any, index: number) => (
                    <Text style={styles.subTitle} key={index}>
                      {handleName(item.x, key)} : {Math.round(item?.y)}%
                    </Text>
                  ))}
                  <Text style={styles.title}>Femme : </Text>
                  {item.all?.[`${key}Female`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                  <Text style={styles.title}>Inférieur à 18 : </Text>
                  {item.all?.[`${key}Inf18`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                  <Text style={styles.title}>Entre 18 et 40 : </Text>
                  {item.all?.[`${key}In18-40`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                  <Text style={styles.title}>Entre 41 et 60 : </Text>
                  {item.all?.[`${key}In41-60`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                  <Text style={styles.title}>Supérieur à 60 : </Text>
                  {item.all?.[`${key}Sup60`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                  <Text style={styles.title}>Supérieur à 70 : </Text>
                  {item.all?.[`${key}Sup70`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                  <Text style={styles.title}>Supérieur à 80 : </Text>
                  {item.all?.[`${key}Sup80`]?.map(
                    (item: any, index: number) => (
                      <Text style={styles.subTitle} key={index}>
                        {handleName(item.x, key)} : {Math.round(item?.y)}%
                      </Text>
                    )
                  )}
                </View>
              </Fragment>
            );
          })}
      </Page>
    </Document>
  );
};
export default StatisticsPDF;
