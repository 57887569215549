import React from 'react';
//Material
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
//Style
import { RouteComponentProps } from 'react-router-dom';
import { useStyles } from './notFound.styles';

const NotFound: React.FC<RouteComponentProps> = ({ location }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant='h5' component='h3'>
        No match for <code>{location.pathname}</code>
      </Typography>
    </Paper>
  );
};

export default NotFound;
