import axios from 'axios';
import { URL } from './configApi';
import {
  INTERVENER_PROFILE_GET_INTERVENER_SUCCESS,
  INTERVENER_PROFILE_GET_INTERVENER_FAILED,
  INTERVENER_PROFILE_PROPS_CHANGED,
  INTERVENER_PROFILE_PROPS_VALIDATION,
  INTERVENER_PROFILE_CREATE_INTERVENER_SUCCESS,
  INTERVENER_PROFILE_CREATE_INTERVENER_FAILED,
  INTERVENER_PROFILE_UPDATE_INTERVENER_SUCCESS,
  INTERVENER_PROFILE_UPDATE_INTERVENER_FAILED,
  INTERVENER_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  IntervenerProfileState,
  IntervenerProfilePropsChangedAction,
  IntervenerProfileInitialStateAction,
} from '../modules/IntervenerProfile/intervenerProfile.types';
import { GetUserApiResponse } from './typesApi';
// Utils

import {
  validateFieldsIntervenerProfile,
  intervenerProfileCreateIntervenerObject,
  intervenerProfileUpdateIntervenerObject,
} from '../modules/IntervenerProfile/intervenerProfile.utils';

export const intervenerProfileGetIntervener =
  (intervenerId: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${URL}/users/${intervenerId}`)
      .then(({ data }: { data: GetUserApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: INTERVENER_PROFILE_GET_INTERVENER_SUCCESS,
            payload: data.response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: INTERVENER_PROFILE_GET_INTERVENER_FAILED,
            payload: 'Aucun utilisateur trouvé avec cet identifiant.',
          });
        } else if (error.response.status === 500) {
          dispatch({
            type: INTERVENER_PROFILE_GET_INTERVENER_FAILED,
            payload: 'Identifiant Utiliateur est incorrect',
          });
        }
      });
  };

export const intervenerProfilePropsChanged = (
  prop: string,
  value: any
): IntervenerProfilePropsChangedAction => ({
  type: INTERVENER_PROFILE_PROPS_CHANGED,
  prop,
  value,
});

export const intervenerProfileCreateIntervener =
  (props: IntervenerProfileState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsIntervenerProfile(props);
    dispatch({ type: INTERVENER_PROFILE_PROPS_VALIDATION, payload: newProps });
    const profile = intervenerProfileCreateIntervenerObject(newProps);

    if (valid && profile) {
      dispatch({
        type: INTERVENER_PROFILE_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });

      axios
        .post(`${URL}/users`, profile)
        .then(({ data }: { data: GetUserApiResponse }) => {
          if (data.status === 'success') {
            dispatch({
              type: INTERVENER_PROFILE_CREATE_INTERVENER_SUCCESS,
            });
            callback();
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            dispatch({
              type: INTERVENER_PROFILE_CREATE_INTERVENER_FAILED,
              payload: 'Utilisateur existe déjà.',
            });
          }
        });
    }
  };

export const intervenerProfileUpdateIntervener =
  (
    userId: string,
    props: IntervenerProfileState,
    callback: () => void
  ): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsIntervenerProfile(props);
    dispatch({ type: INTERVENER_PROFILE_PROPS_VALIDATION, payload: newProps });
    const profile = intervenerProfileUpdateIntervenerObject(newProps);

    if (valid && profile) {
      dispatch({
        type: INTERVENER_PROFILE_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });

      axios
        .put(`${URL}/users/${userId}`, profile)
        .then(({ data }: { data: GetUserApiResponse }) => {
          if (data.status === 'success') {
            dispatch({
              type: INTERVENER_PROFILE_UPDATE_INTERVENER_SUCCESS,
              payload: data.response.data,
            });
            callback();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            dispatch({
              type: INTERVENER_PROFILE_UPDATE_INTERVENER_FAILED,
              payload: 'Aucun utilisateur trouvé avec cet identifiant.',
            });
          }
        });
    }
  };

export const intervenerProfileIntialState =
  (): IntervenerProfileInitialStateAction => ({
    type: INTERVENER_PROFILE_INITIAL_STATE,
  });
