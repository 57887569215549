import axios from 'axios';
import { URL } from './configApi';
import {
  FORGOT_PASSWORD_PROPS_CHANGED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_INITIAL_STATE,
  AppThunk,
} from './types';

import {
  ForgotPasswordPropsChangedAction,
  ForgotPasswordInitialStateAction,
} from '../modules/ForgotPassword/forgotPassword.types';

export const forgotPasswordPropsChanged = (
  prop: string,
  value: string
): ForgotPasswordPropsChangedAction => ({
  type: FORGOT_PASSWORD_PROPS_CHANGED,
  prop,
  value,
});

export const forgotPasswordConfirm =
  (email: string, callback: () => void): AppThunk =>
  (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .post(`${URL}/users/forgot_password`, {
        email,
      })
      .then((response) => {
        if (response.data.status === 'success') {
          dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: FORGOT_PASSWORD_FAILED,
            payload: 'Email non trouvé',
          });
        } else if (error.response.status === 500) {
          dispatch({
            type: FORGOT_PASSWORD_FAILED,
            payload:
              "Une erreur s'est produite lors de l'envoi de l'e-mail. Réessayez plus tard!",
          });
        }
      });
  };

export const forgotPasswordInitialState =
  (): ForgotPasswordInitialStateAction => ({
    type: FORGOT_PASSWORD_INITIAL_STATE,
  });
