/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import axios from 'axios';

import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getItemFromLocalStorage } from './storage';

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const token = getItemFromLocalStorage('token');
  const user: any = getItemFromLocalStorage('user');

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const Component: any = component;
  return (
    <Route
      {...rest}
      render={(props) =>
        token && user && user.accessBackOffice ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
