import axios from 'axios';
import { URL } from './configApi';
import {
  UPDATE_PASSWORD_PROPS_CHANGED,
  UPDATE_PASSWORD_PROPS_VALIDATION,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  UpdatePasswordState,
  UpdatePasswordPropsChangedAction,
  UpdatePasswordInitialStateAction,
} from '../modules/UpdatePassword/updatePassword.types';

import { validateFieldsPUpdatePassword } from '../modules/UpdatePassword/updatePassword.utils';

export const updatePasswordPropsChanged = (
  prop: string,
  value: any
): UpdatePasswordPropsChangedAction => ({
  type: UPDATE_PASSWORD_PROPS_CHANGED,
  prop,
  value,
});

export const updatePasswordConfirm =
  (props: UpdatePasswordState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsPUpdatePassword(props);
    dispatch({ type: UPDATE_PASSWORD_PROPS_VALIDATION, payload: newProps });

    if (valid) {
      dispatch({
        type: UPDATE_PASSWORD_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });

      axios
        .patch(`${URL}/users/update_password`, {
          passwordCurrent: props.passwordCurrent,
          password: props.newPassword,
          passwordConfirm: props.passwordConfirm,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            dispatch({
              type: UPDATE_PASSWORD_SUCCESS,
            });
            callback();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch({
              type: UPDATE_PASSWORD_FAILED,
              payload: 'Votre mot de passe actuel est incorrect.',
            });
          }
        });
    }
  };

export const updatePasswordInitialState =
  (): UpdatePasswordInitialStateAction => ({
    type: UPDATE_PASSWORD_INITIAL_STATE,
  });
