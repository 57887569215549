import axios from 'axios';
import { URL } from './configApi';
import {
  MANAGER_PROFILE_PROPS_CHANGED,
  MANAGER_PROFILE_PROPS_VALIDATION,
  GET_CURRENT_MANAGER_FAILED,
  GET_CURRENT_MANAGER_SUCCES,
  MANAGER_PROFILE_SUCCESS,
  MANAGER_PROFILE_FAILED,
  MANAGER_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  ManagerProfileState,
  ManagerProfilePropsChangedAction,
  ManagerProfileInitialStateAction,
} from '../modules/ManagerProfile/managerProfile.types';
import { GetUserApiResponse } from './typesApi';

import {
  validateFieldsManagerProfile,
  managerProfileObject,
} from '../modules/ManagerProfile/managerProfile.utils';
import { setItemInLocalStorage } from '../utils/storage';

export const managerProfilePropsChanged = (
  prop: string,
  value: any
): ManagerProfilePropsChangedAction => ({
  type: MANAGER_PROFILE_PROPS_CHANGED,
  prop,
  value,
});

export const managerProfileGetCurrentManager = (): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/users/me`)
    .then(({ data }: { data: GetUserApiResponse }) => {
      if (data.status === 'success') {
        dispatch({
          type: GET_CURRENT_MANAGER_SUCCES,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: GET_CURRENT_MANAGER_FAILED,
          payload: 'Echec de la mise à jour du profil',
        });
      }
    });
};

export const managerProfileConfirm =
  (props: ManagerProfileState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsManagerProfile(props);
    dispatch({ type: MANAGER_PROFILE_PROPS_VALIDATION, payload: newProps });
    const profile = managerProfileObject(newProps);

    if (valid && profile) {
      dispatch({
        type: MANAGER_PROFILE_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });
      dispatch({
        type: MANAGER_PROFILE_PROPS_CHANGED,
        prop: 'loadingPage',
        value: true,
      });

      axios
        .put(`${URL}/users/update_my_account`, profile)
        .then(({ data }: { data: GetUserApiResponse }) => {
          if (data.status === 'success') {
            setItemInLocalStorage('user', data.response.data);

            dispatch({
              type: MANAGER_PROFILE_SUCCESS,
              payload: data.response.data,
            });
            callback();
          }
        })
        .catch((error) => {
          dispatch({
            type: MANAGER_PROFILE_FAILED,
            payload: 'error',
          });
        });
    }
  };

export const managerProfileIntialState =
  (): ManagerProfileInitialStateAction => ({
    type: MANAGER_PROFILE_INITIAL_STATE,
  });
