import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
//Styles
//Material
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
//Commons
import moment from 'moment';
import Button from '../../../commons/Button';
//Actions
import { URL_IMAGE } from '../../../actions/configApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: 'none',
    },
    container: {
      maxHeight: '100%',
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F6F9FD',
      },
    },
  })
)(TableRow);

interface StickyHeadTableProps extends RouteComponentProps {
  data?: Array<any>;
  count: number;
  page: number;
  rowsPerPage: number;
  changePage: (event: unknown, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clickDetail: (value: string) => void;
  clickEdit: (value: string) => void;
  clickDelete: (value: string) => void;
}

// type PermissionType = 'read' | 'read/write' | 'speakers';

const StickyHeadTable: React.FC<StickyHeadTableProps> = (props) => {
  const classes = useStyles();

  const {
    data,
    count,
    rowsPerPage,
    page,
    changePage,
    changeRowsPerPage,
    clickEdit,
    clickDelete,
    clickDetail,
  } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Organisateur</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Département</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Date début</TableCell>
              <TableCell>Date fin</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row._id}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {row.organizer &&
                        `${row.organizer.firstName} ${row.organizer.lastName}`}
                    </TableCell>
                    <TableCell>{row.phoneNumber && row.phoneNumber}</TableCell>
                    <TableCell>
                      {row.department && row.department.name}
                    </TableCell>
                    <TableCell>{row.address && row.address}</TableCell>
                    <TableCell>
                      {row.startDate &&
                        moment(row.startDate).format('DD/MM/yyyy')}
                    </TableCell>
                    <TableCell>
                      {row.endDate && moment(row.endDate).format('DD/MM/yyyy')}
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={() => clickDetail(row._id)}
                        width={90}
                        background='#dd0089'
                      >
                        Voir Détails
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          props.history.push(`/campagnes/${row._id}`)
                        }
                        width={90}
                        background='#FFA550'
                      >
                        Modifier
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Button
                        onClick={() => clickDelete(row._id)}
                        width={90}
                        background='#FF67C6'
                      >
                        Supprimer
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage='Lignes par page'
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </Paper>
  );
};

export default withRouter(StickyHeadTable);
