import axios from 'axios';
import { URL } from './configApi';
import {
  CAMPAGNE_DETAIL_GET_CAMPAGNE_SUCCESS,
  CAMPAGNE_DETAIL_GET_CAMPAGNE_FAILED,
  CAMPAGNE_DETAIL_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  CampagneDetailState,
  CampagneDetailInitialStateAction,
} from '../modules/CampagneDetail/campagneDetail.types';

// Utils
import { campagneProfileGetCampagneObject } from '../modules/CampagneProfile/campagneProfile.utils';

export const campagneDetailGetCampagne =
  (campagneId: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${URL}/campagnes/${campagneId}`)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          const campagne = campagneProfileGetCampagneObject(data.response.data);

          dispatch({
            type: CAMPAGNE_DETAIL_GET_CAMPAGNE_SUCCESS,
            payload: campagne,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: CAMPAGNE_DETAIL_GET_CAMPAGNE_FAILED,
            payload: 'Campagne introuvable.',
          });
        }
      });
  };

export const campagneDetailInitialState =
  (): CampagneDetailInitialStateAction => ({
    type: CAMPAGNE_DETAIL_INITIAL_STATE,
  });
