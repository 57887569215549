import axios from 'axios';
import { URL } from './configApi';
import {
  ADD_INTERVENER_PROPS_CHANGED,
  ADD_INTERVENER_PROPS_VALIDATION,
  CAMPAGNE_PROFILE_ADD_INTERVENER,
  ADD_INTERVENER_FAILED,
  ADD_INTERVENER_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  AddIntervenerState,
  AddIntervenerPropsChangedAction,
  AddIntervenerInitialStateAction,
} from '../modules/AddIntervener/addIntervener.types';
import { GetUserApiResponse } from './typesApi';
// Utils From UserProfile
import {
  validateFieldsIntervenerProfile,
  intervenerProfileCreateIntervenerObject,
} from '../modules/IntervenerProfile/intervenerProfile.utils';

export const addIntervenerPropsChanged = (
  prop: string,
  value: any
): AddIntervenerPropsChangedAction => ({
  type: ADD_INTERVENER_PROPS_CHANGED,
  prop,
  value,
});

export const addIntervenerConfirm =
  (props: AddIntervenerState, callback: () => void): AppThunk =>
  (dispatch) => {
    const { newProps, valid } = validateFieldsIntervenerProfile(props);
    dispatch({ type: ADD_INTERVENER_PROPS_VALIDATION, payload: newProps });
    const profile = intervenerProfileCreateIntervenerObject(newProps);

    if (valid && profile) {
      dispatch({
        type: ADD_INTERVENER_PROPS_CHANGED,
        prop: 'loading',
        value: true,
      });
      axios
        .post(`${URL}/users`, profile)
        .then(({ data }: { data: GetUserApiResponse }) => {
          if (data.status === 'success') {
            // Dispatch to campagneProfileReducer
            dispatch({
              type: CAMPAGNE_PROFILE_ADD_INTERVENER,
              payload: {
                _id: data.response.data._id,
                name: `${data.response.data.firstName} ${data.response.data.firstName}`,
              },
            });
            dispatch({
              type: ADD_INTERVENER_PROPS_CHANGED,
              prop: 'loading',
              value: false,
            });
            callback();
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            dispatch({
              type: ADD_INTERVENER_FAILED,
              payload: 'Utilisateur existe déjà.',
            });
          }
        });
    }
  };

export const addIntervenerInitialState =
  (): AddIntervenerInitialStateAction => ({
    type: ADD_INTERVENER_INITIAL_STATE,
  });
